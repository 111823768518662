import { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Welcome() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  if (localStorage.getItem("email") === null && isLoggedIn) {
    setIsLoggedIn(false);
  } else if (localStorage.getItem("email") !== null && !isLoggedIn) {
    setIsLoggedIn(true);
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="w-full">
        <div className="flex w-full py-8 px-12" style={{ backgroundColor: "#03457b" }}>
          <div className="text-white">
            <h1 className="text-5xl font-semibold">
              Database of Glasses for Nuclear Waste Storage
            </h1>
            <div className="flex gap-2 mt-6">
              {isLoggedIn ? (
                <></>
              ) : (
                <NavLink
                  to="/login"
                  className="py-2 px-6 my-auto rounded-md bg-orange-500 text-white font-light transition-all hover:cursor-pointer hover:bg-orange-600"
                >
                  Login or Register
                </NavLink>
              )}

              <NavLink
                to="/database"
                className={
                  "py-2 px-6 my-auto rounded-md hover:cursor-pointer" +
                  " " +
                  (isLoggedIn
                    ? "bg-orange-500 text-white font-light transition-all hover:bg-orange-600"
                    : "border-[1px] border-orange-500 text-orange-500 font-light transition-all hover:bg-orange-500 hover:text-white")
                }
              >
                Explore Glass Database
              </NavLink>
              <NavLink
                  to="/glasspy"
                  className={
                      "py-2 px-6 my-auto rounded-md hover:cursor-pointer" +
                      " " +
                      (isLoggedIn
                          ? "bg-orange-500 text-white font-light transition-all hover:bg-orange-600"
                          : "border-[1px] border-orange-500 text-orange-500 font-light transition-all hover:bg-orange-500 hover:text-white")
                  }
              >
                Explore GlassPy
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 rounded-md border-4 w-full h-auto p-3 mt-5 ">
          <img
              src="/dashboard.jpg"
              alt="logo"
              className="w-full object-contain"
              style={{ height: '500px' }}
          />
        </div>
      </div>
      <div className="flex-1"></div>
      {/*<footer className="py-4 bg-neutral-100 border border-t-1 text-center font-light text-sm">*/}
      {/*  Copyright All Rights Reserved 2024 by Savannah River National Laboratory*/}
      {/*</footer>*/}
    </div>
  );
}
