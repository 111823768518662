// Custon utility functions

// Parse CSV to array
import toast from "react-hot-toast";

export function CSVtoArray(text: string): Array<string> {
  const re_valid =
    /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
  const re_value =
    /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;

  // Return NULL if input string is not well formed CSV string.
  if (!re_valid.test(text)) return [];

  const a: Array<string> = []; // Initialize array to receive values.
  text.replace(
    re_value, // "Walk" the string using replace with callback.
    function (_, m1, m2, m3) {
      // Remove backslash from \' in single quoted values.
      if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
      // Remove backslash from \" in double quoted values.
      else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
      else if (m3 !== undefined) a.push(m3);
      return ""; // Return empty string.
    }
  );
  // Handle special case of empty last value.
  if (/,\s*$/.test(text)) a.push("");
  return a;
}


export const notifyPopulateSuccess = (message: string) =>
    toast.success(message || "Success", {
        icon: "✅",
        style: { borderRadius: "10px", background: "#22c55e", color: "#fff" },
    });

export const notifyFailure = (message: string) =>
    toast.success(message || "Failed", {
        icon: "❌",
        style: { borderRadius: "10px", background: "#ef4444", color: "#fff" },
    });


export const formatSubscript = (formula: string): string => {
    const subscriptMap: { [key: string]: string } = {
        '0': '₀',
        '1': '₁',
        '2': '₂',
        '3': '₃',
        '4': '₄',
        '5': '₅',
        '6': '₆',
        '7': '₇',
        '8': '₈',
        '9': '₉'
    };

    return formula.replace(/\d/g, (digit) => subscriptMap[digit] || digit);
};

export const formatSubscriptWithHTML = (formula: string) => {
    return formula.replace(/\d/g, (digit) => `<sub>${digit}</sub>`);
}

