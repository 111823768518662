import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Grid, Alert } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const ContactUs: React.FC = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        message: false,
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // Reset previous errors
        setErrors({
            name: false,
            email: false,
            message: false,
        });

        // Validate required fields
        const newErrors = {
            name: formData.name === '',
            email: formData.email === '' || !validateEmail(formData.email),
            message: formData.message === '',
        };

        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some((error) => error);
        if (hasErrors) return;

        // Mock sending the form data
        setTimeout(() => {
            setSuccessMessage('Thank you for your message! We will be in touch soon.');
            setFormData({ name: '', email: '', phone: '', message: '' });
        }, 1000);
    };

    return (
        <Box
            sx={{
                padding: '2rem',
                backgroundColor: '#ffffff',
                color: '#003366',
                borderRadius: '16px',
                border: '2px solid #3399FF', // Light blue border
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                maxWidth: '900px',
                margin: '2rem auto',
                marginTop: '4rem',
            }}
        >
            <Grid container spacing={4}>
                {/* Left Column */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#003366' }}>
                        Got a Query? Reach Out to Our Team
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '1.5rem', color: '#003366' }}>
                        We're here to help! Contact us using the form or get in touch with one of our team members directly.
                    </Typography>

                    {/* Team Member Information */}
                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Cory Trivelpiece | <span className="font-semibold font text-blue-600"> SRNL </span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}><EmailIcon /> Cory.Trivelpiece@srnl.doe.gov</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Xiaonan Lu | <span className="font-semibold font text-orange-900"> PNNL</span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}> <EmailIcon /> xiaonan.lu@pnnl.gov</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Collin Wilkinson | <span className="font-semibold font text-purple-600"> Alfred University </span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}><EmailIcon /> wilkinsonc@alfred.edu </Typography>
                    </Box>

                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Elizabeth Tsekrekas | <span className="font-semibold font text-blue-600"> SRNL </span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}><EmailIcon /> elizabeth.tsekrekas@srnl.doe.gov</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Dilpuneet Aidhy | <span className="font-semibold font text-orange-500"> Clemson University</span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}><EmailIcon /> daidhy@clemson.edu</Typography>
                    </Box>

                    <Box sx={{ marginBottom: '1rem' }}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#003366', fontSize: '.9rem' }}>
                            Mohit Singh | <span className="font-light font-semibold text-orange-500"> Clemson University</span>
                        </Typography>
                        <Typography sx={{ color: '#003366', fontSize: '0.8rem' }}><EmailIcon /> msingh3@clemson.edu</Typography>
                    </Box>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={6}>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1rem',
                            backgroundColor: '#f0f0f0',
                            padding: '2rem',
                            borderRadius: '8px',
                        }}
                    >
                        <TextField
                            fullWidth
                            label="Name"
                            placeholder="e.g John Doe"
                            variant="outlined"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                            error={errors.name}
                            helperText={errors.name ? 'Name is required' : ''}
                            InputLabelProps={{
                                style: { color: '#003366' },
                            }}
                            InputProps={{
                                style: { backgroundColor: '#ffffff', color: '#003366' },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            placeholder="e.g johndoe@mail.com"
                            variant="outlined"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            error={errors.email}
                            helperText={errors.email ? 'Valid email is required' : ''}
                            InputLabelProps={{
                                style: { color: '#003366' },
                            }}
                            InputProps={{
                                style: { backgroundColor: '#ffffff', color: '#003366' },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Phone"
                            placeholder="Phone Number"
                            variant="outlined"
                            name="phone"
                            value={formData.phone}
                            onChange={handleInputChange}
                            InputLabelProps={{
                                style: { color: '#003366' },
                            }}
                            InputProps={{
                                style: { backgroundColor: '#ffffff', color: '#003366' },
                            }}
                        />
                        <TextField
                            fullWidth
                            label="Message"
                            placeholder="Write message..."
                            variant="outlined"
                            multiline
                            rows={4}
                            name="message"
                            value={formData.message}
                            onChange={handleInputChange}
                            error={errors.message}
                            helperText={errors.message ? 'Message is required' : ''}
                            InputLabelProps={{
                                style: { color: '#003366' },
                            }}
                            InputProps={{
                                style: { backgroundColor: '#ffffff', color: '#003366' },
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                alignSelf: 'flex-start',
                                backgroundColor: '#FF6600',
                                color: '#ffffff',
                                '&:hover': { backgroundColor: '#e65c00' },
                            }}
                        >
                            SEND
                        </Button>
                    </Box>
                    {successMessage && (
                        <Alert sx={{ marginTop: '1rem', backgroundColor: '#d4edda', color: '#155724' }} severity="success">
                            {successMessage}
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactUs;
