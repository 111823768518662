import React from "react";
import BasicTabs from "./BasicTabs";

export default function GlassPyMain() {
    return (
        <>
            <BasicTabs />
        </>
    );
}
