import { useRef, useState } from "react";
import parse from "html-react-parser";
import { AiFillCaretDown } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import toast, { Toaster } from "react-hot-toast";

import * as apiAdmin from "../../api/admin";
import MenuSelect from "../Common/MenuSelect";
import {DatabaseOptions} from "../Constants/base";
import {notifyFailure, notifyPopulateSuccess} from "../../utils";
import {Dialog, DialogContent, LinearProgress} from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';
import * as React from "react";
import {DeleteCollection} from "../../api/admin";

interface ColRange {
  from: number;
  to: number;
}


const ROWS_PER_PAGE: Array<number> = [10, 25, 50, 100, 200];

export default function UpdateDatabase() {
  const inputFileRef = useRef(null);
  const fileReader = new FileReader();

  const [file, setFile] = useState<File>();
  const [parsedData, setParsedData] = useState<Array<Array<string>>>([]); // First row is header
  const [compColRange, setCompColRange] = useState<ColRange>({
    from: 0,
    to: 0,
  });
  const [propColRange, setPropColRange] = useState<ColRange>({
    from: 0,
    to: 0,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);

  const [selectedDb, setSelectedDb] = useState(0);


  const idxLastRow = currentPage * rowsPerPage;
  const idxFirstRow = idxLastRow - rowsPerPage;
  const currentRows = parsedData.slice(idxFirstRow + 1, idxLastRow);

  const handleDbChange = async (event: any) => {
    setSelectedDb(event.target.value);
  };

  const paginate = (props: { selected: number }) => {
    setCurrentPage(props.selected + 1);
  };

  const delimToArray = (text: any, delim: string, type: string) => {

    let headers = [];
    let rows = [];
    let rowNumIdx = -1;

    if (type === '.txt') {
      text = text.replaceAll("\r", "").replaceAll('"', "");
      headers = text.slice(0, text.indexOf("\n")).split(delim);
      rows = text.slice(text.indexOf("\n") + 1).split("\n");

      rowNumIdx = headers.indexOf("Row Number");
      if (rowNumIdx !== -1) headers.splice(rowNumIdx, 1);
    } else if (type === '.csv') {

      const lines = text.split("\n");

      headers = lines[0].split(delim).map((header: string)  => header.trim());
      rows = lines.slice(1).map((line: string) => line.split("\n"));
      //console.log(rows);
    }

    const array: Array<Array<string>> = [];
    array.push(headers);

    if (type === '.txt') {
      for (const row of rows) {
        const values = row.split(delim);
        if (rowNumIdx !== -1) values.splice(rowNumIdx, 1);

        if (values.length !== headers.length) {
          setErrorMsg("Mismatched header and content length.");
          //console.log(values.length, headers.length);
          //console.log(values, headers);
          return;
        }

        array.push(values);
      }
    } else if (type === '.csv') {
      for (const row of rows) {
        const values = row[0].split(delim);

        if (values.length !== headers.length) {
          setErrorMsg("Mismatched header and content length.");
          // console.log(values.length, headers.length);
          // console.log(values, headers);
          continue;
          //return;
        }

        array.push(values);
      }
    }

    setErrorMsg("");
    setParsedData(array);
  };

  const getColLabelColor = (colIdx: number) => {
    if (colIdx >= compColRange.from && colIdx <= compColRange.to) {
      return "bg-green-600 text-white";
    }

    if (colIdx >= propColRange.from && colIdx <= propColRange.to) {
      return "bg-blue-500 text-white";
    }

    return "bg-neutral-200 text-black";
  };

  const onChangeFile = (files: FileList | null) => {
    if (files === null) return;
    setFile(files[0]);
  };

  const onClickFileName = () => {
    setFile(undefined);
    setParsedData([]);
    setCompColRange({ from: 0, to: 0 });
    setPropColRange({ from: 0, to: 0 });
    //@ts-ignore
    inputFileRef.current.value = null;
  };

  const onClickParse = () => {
    setErrorMsg("");
    setParsedData([]);

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target?.result;

        let delimiter = "\t"; // Default delimiter for .txt files
        let type = '.txt';

        // Determine the delimiter based on file type
        if (file.name.endsWith('.csv')) {
          delimiter = ','; // Use comma for CSV files
          type = '.csv';
        } else if (file.name.endsWith('.txt')) {
          delimiter = "\t"; // Use tab for TXT files (or any other default)
        }

        // Process the text with the determined delimiter
        delimToArray(text , delimiter, type);


      };
      fileReader.readAsText(file);
    }
  };

  const [progress, setProgress] = useState<number>(0);

  const onClickPopulate = async () => {
    setErrorMsg("");

    if (compColRange.from < 0 || compColRange.to > parsedData[0].length) {
      setErrorMsg(
        "Composition From column must be ≥ 0 and ≤ " + parsedData[0].length
      );
      return;
    }
    if (propColRange.from < 0 || propColRange.to > parsedData[0].length) {
      setErrorMsg(
        "Property From column must be ≥ 0 and ≤ " + parsedData[0].length
      );
      return;
    }
    if (compColRange.from > compColRange.to) {
      setErrorMsg("Composition From column must be ≤ To column.");
      return;
    }
    if (propColRange.from > propColRange.to) {
      setErrorMsg("Property From column must be ≤ To column.");
      return;
    }
    if (compColRange.from < propColRange.from) {
      if (compColRange.to >= propColRange.from) {
        setErrorMsg("Composition and property column ranges are overlapping.");
        return;
      }
    } else {
      if (compColRange.from <= propColRange.to) {
        setErrorMsg("Composition and property column ranges are overlapping.");
        return;
      }
    }

    setIsLoading(true);
    setProgress(0);

    const selectedLabel = DatabaseOptions.find(obj => obj.value === selectedDb)?.label || selectedDb;


    try {

      const response = await handleUpload()

      if (response && response.statusCode !== 200) {
        notifyFailure("Failed to upload file. Please try again.");
        return;
      }

      const resp = await apiAdmin.DeleteCollection(
          selectedLabel as string
      );

      if (!resp || !resp.data || resp.data.statusCode !== 200) {
        notifyFailure("Failed to delete the collection. Please try again.");
        setIsLoading(false);
        return;
      }

      // Function to chunk the data
      // Function to chunk the data while ensuring the header is included in every chunk
      function chunkArrayWithHeader(array: any[], chunkSize: number): any[][] {
        if (array.length === 0) return [];

        const header = array[0]; // Extract the header row
        const dataRows = array.slice(1); // The remaining rows are the data
        const result = [];

        for (let i = 0; i < dataRows.length; i += chunkSize) {
          // Add the header as the first row in each chunk
          result.push([header, ...dataRows.slice(i, i + chunkSize)]);
        }

        return result;
      }

      // Usage
      const chunkedData = chunkArrayWithHeader(parsedData, 100);
      const totalChunks = chunkedData.length;

      for (let i = 0; i < totalChunks; i++) {
        const chunk = chunkedData[i];

        console.log(selectedLabel, chunk, compColRange.from - 1, compColRange.to - 1, propColRange.from - 1, propColRange.to - 1)

        // Call the API for each chunk
        await apiAdmin.populate(
            selectedLabel as string,
            chunk,
            compColRange.from - 1,
            compColRange.to - 1,
            propColRange.from - 1,
            propColRange.to - 1
        );

        // Update the progress percentage
        setProgress(Math.round(((i + 1) / totalChunks) * 100));
      }

      notifyPopulateSuccess("Database has been uploaded.");
    } catch (error) {
      notifyFailure("Failed to upload the data. Please try again.");
      setIsLoading(false);
    }

    setIsLoading(false);

  };


  const convertToCSV = (data: any) => {
    return data.map((row: any) => row.join(",")).join("\n");
  };

  const handleUpload = async () => {
    try {
      // Convert parsed data to CSV
      const csvContent = convertToCSV(parsedData);

      const selectedLabel = DatabaseOptions.find(obj => obj.value === selectedDb)?.label || selectedDb;

      const response = await fetch(`${process.env.REACT_APP_AWS_GET_URL}?filename=${selectedLabel}`, {
        method: 'GET', // Adjust to 'POST', 'PUT', etc., if needed
        headers: {
          'Content-Type': 'application/json',
          // Add any custom headers your backend requires
          'Authorization': 'Bearer '+localStorage.getItem("access_token"), // If needed
        },
        mode: 'cors', // This is the default, but explicitly setting it might help
        credentials: 'include', // Use 'include' to send cookies with the request (useful for authentication)
      });

      const {data } = await response.json();

      // console.log(data, process.env.REACT_APP_AWS_GET_URL, data.url)

      // Step 2: Upload the CSV to S3 using the pre-signed URL
      const uploadResponse = await fetch(data.url, {
        method: "PUT",
        headers: {
          "Content-Type": "text/csv", // Set the content type to CSV
        },
        body: new Blob([csvContent], { type: "text/csv" }), // Convert the CSV content to a Blob
      });

      if (uploadResponse.ok) {
        return {statusCode: 200, message: "File uploaded successfully"};
      } else {
        return {statusCode: 400, message: "Failed to upload file"};
      }
    } catch (err) {
      return {statusCode: 400, message: "Failed to upload file"};
    }
  };

  return (
    <>
      <Toaster position="top-right" reverseOrder={true} />

      <div className="w-full h-full flex flex-col gap-4">
        {/* Upload file pane */}
        <div className="flex w-full bg-white rounded-md p-4 items-center gap-4">
          <div className="w-full flex items-center gap-4">
            <label className="min-w-max shadow-md rounded-md text-center py-3 px-8 bg-white font-light hover:cursor-pointer hover:bg-neutral-200">
              <input
                ref={inputFileRef}
                type="file"
                accept=".txt,.csv"
                className="hidden"
                onChange={(e) => onChangeFile(e.target.files)}
                disabled={isLoading}
              />
              Choose an indent delimited .txt/.csv file
            </label>
            <div className="w-4/12">
              <MenuSelect
                  label="Choose a database"
                  options={DatabaseOptions}
                  value={selectedDb}
                  onChange={handleDbChange}
              />
            </div>
            {file ? (
              <div
                className="font-light hover:cursor-pointer hover:text-red-600"
                onClick={onClickFileName}
              >
                {file?.name} ({(file.size / 1024).toFixed(2)} KB)
              </div>
            ) : (
              <></>
            )}
            {parsedData.length > 0 ? (
              <div className="font-light">
                {parsedData[0].length} Columns | {parsedData.length} Rows
              </div>
            ) : (
              <></>
            )}
          </div>

          {/*{isLoading ? (*/}
          {/*  <img*/}
          {/*    src="/loading.gif"*/}
          {/*    alt="loading..."*/}
          {/*    className="aspect-square max-h-8"*/}
          {/*  />*/}
          {/*) : (*/}
          {/*  <></>*/}
          {/*)}*/}

          <button
            type="button"
            className={
              "min-w-max py-2 px-6 rounded-md bg-orange-500 text-white font-light transition-all " +
              (file !== undefined
                ? "hover:bg-orange-600"
                : "opacity-70 hover:cursor-default")
            }
            onClick={() => {
              if (file !== undefined) {
                if (parsedData.length > 0) onClickPopulate();
                else onClickParse();
              }
            }}
          >
            {parsedData.length > 0 ? "Populate Database" : "Parse File"}
          </button>
        </div>

        {errorMsg.length > 0 ? (
          <div className="w-full text-center text-red-600">{errorMsg}</div>
        ) : (
          <></>
        )}

        {/* Column labelling section */}
        <div className="w-full">
          {parsedData.length > 0 ? (
            <div className="flex gap-4">
              <div className="flex flex-col w-full bg-white p-4 rounded-md gap-2">
                <div className="flex gap-2 items-center">
                  <h1>Composition Columns</h1>
                  <div className="w-4 h-4 rounded-full bg-green-600"></div>
                </div>
                <div className="flex gap-4">
                  <label className="w-full flex shadow-md rounded-md text-center py-3 px-2 bg-white font-light gap-4">
                    From
                    <input
                      className="w-full mx-4 px-2 border-b-2"
                      type="number"
                      min={0}
                      max={parsedData[0].length}
                      value={compColRange.from}
                      onChange={(e) => {
                        setCompColRange({
                          from: +e.target.value,
                          to: compColRange.to,
                        });
                      }}
                      disabled={isLoading}
                    />
                  </label>
                  <label className="w-full flex shadow-md rounded-md text-center py-3 px-2 bg-white font-light gap-4">
                    To
                    <input
                      className="w-full mx-4 px-2 border-b-2"
                      type="number"
                      min={0}
                      max={parsedData[0].length}
                      value={compColRange.to}
                      onChange={(e) => {
                        setCompColRange({
                          from: compColRange.from,
                          to: +e.target.value,
                        });
                      }}
                      disabled={isLoading}
                    />
                  </label>
                </div>
              </div>

              <div className="flex flex-col w-full bg-white p-4 rounded-md gap-2">
                <div className="flex gap-2 items-center">
                  <h1>Property Columns</h1>
                  <div className="w-4 h-4 rounded-full bg-blue-500"></div>
                </div>
                <div className="flex gap-4">
                  <label className="w-full flex shadow-md rounded-md text-center py-3 px-2 bg-white font-light gap-4">
                    From
                    <input
                      className="w-full mx-4 px-2 border-b-2"
                      type="number"
                      min={0}
                      max={parsedData[0].length}
                      value={propColRange.from}
                      onChange={(e) => {
                        setPropColRange({
                          from: +e.target.value,
                          to: propColRange.to,
                        });
                      }}
                      disabled={isLoading}
                    />
                  </label>
                  <label className="w-full flex shadow-md rounded-md text-center py-3 px-2 bg-white font-light gap-4">
                    To
                    <input
                      className="w-full mx-4 px-2 border-b-2"
                      type="number"
                      min={0}
                      max={parsedData[0].length}
                      value={propColRange.to}
                      onChange={(e) => {
                        setPropColRange({
                          from: propColRange.from,
                          to: +e.target.value,
                        });
                      }}
                      disabled={isLoading}
                    />
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {/* Display Table */}
        {parsedData.length > 0 ? (
          <div className="bg-white py-2 px-4 rounded-md">
            <div className="flex flex-row-reverse pb-2 text-sm font-light">
              <button
                type="button"
                className="flex items-center gap-2"
                onClick={() => setIsDropDownOpen(!isDropDownOpen)}
              >
                {rowsPerPage} rows per page <AiFillCaretDown />
              </button>
              {isDropDownOpen ? (
                <ul className="absolute mt-6 border min-w-max bg-white rounded-md py-1">
                  {ROWS_PER_PAGE.map((rpp) => (
                    <li
                      key={rpp}
                      className="py-1 px-6 hover:bg-neutral-200 hover:cursor-pointer"
                      onClick={() => {
                        setRowsPerPage(rpp);
                        setIsDropDownOpen(false);
                      }}
                    >
                      {rpp}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
            </div>
            <div className="overflow-x-scroll">
              <table className="table-auto border-collapse bg-white shadow-lg text-xs min-w-max">
                <thead>
                  <tr>
                    {parsedData[0].map((_, col) => (
                      <th
                        key={col + "-idx"}
                        className={
                          "px-4 py-2 min-w-[6rem] border font-medium " +
                          getColLabelColor(col + 1)
                        }
                      >
                        {col + 1}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    {parsedData[0].map((d, col) => (
                      <th
                        key={col}
                        className="px-4 py-2 min-w-[6rem] border bg-sky-900 text-white"
                      >
                        {d}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {currentRows.map((row, rowIdx) => (
                    <tr
                      key={rowIdx}
                      className={rowIdx % 2 ? "bg-neutral-50" : ""}
                    >
                      {row.map((val, colIdx) => (
                        <td
                          key={colIdx}
                          className={
                            "border border-slate-200 px-2 py-1.5" +
                            " " +
                            (isNaN(+val) ? "" : "text-right")
                          }
                        >
                          {colIdx === 0 ? (
                            <div className="flex items-center">
                              <p className="w-full font-semibold">
                                {parse(val)}
                              </p>
                            </div>
                          ) : (
                            val
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ReactPaginate
              className="w-full flex gap-2 justify-center items-center pt-2 text-sky-800 text-sm"
              onPageChange={paginate}
              pageCount={Math.ceil((parsedData.length - 1) / rowsPerPage)}
              previousLabel={"Prev"}
              nextLabel={"Next"}
              containerClassName={"pagination"}
              pageLinkClassName={"hover:underline hover:font-medium"}
              previousLinkClassName={"hover:underline hover:font-medium"}
              nextLinkClassName={"hover:underline hover:font-medium"}
              activeLinkClassName={"active"}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <Dialog open={isLoading} onClose={() => {}} fullWidth maxWidth="md">
        <DialogContent className="flex flex-col items-center p-5">
          <div className="w-full mb-4">
            <LinearProgress variant="determinate" value={progress} />
          </div>
          <p className="text-center">Uploading... {progress}%</p>
        </DialogContent>
      </Dialog>
    </>
  );
}
