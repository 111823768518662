import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';

// Custom styled input using Material-UI's system
const CustomInput = styled(InputBase)(({ theme }) => ({
    'flex': 1,
    'height': '2.25rem', // 36px
    'width': '100%',
    'borderRadius': theme.shape.borderRadius,
    'border': `1px solid ${theme.palette.action.disabled}`, // Customizable
    'backgroundColor': 'transparent',
    'padding': '0.25rem 0.75rem', // Padding y and x
    'fontSize': '0.875rem', // 14px
    'boxShadow': '0 1px 2px rgba(0, 0, 0, 0.05)',
    '&:focus-visible': {
        outline: 'none',
        boxShadow: `${theme.shadows[1]}`,
        borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {
        color: theme.palette.text.disabled,
    },
    '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
    },
}));

export { CustomInput };
