import React, { useEffect, useRef, useMemo } from 'react';
import * as d3 from 'd3';
import { ZoomBehavior, Selection } from 'd3';

// Define the props using an interface
interface ZoomableProps {
    width: number;
    height: number;
    children: React.ReactNode;
    scaleExtent?: [number, number];
    translateExtent?: [[number, number], [number, number]];
    borderColor?: string;
    borderWidth?: number;
    margin?: { top: number; right: number; bottom: number; left: number };
}

const ZoomInDepth: React.FC<ZoomableProps> = ({
                                               width,
                                               height,
                                               children,
                                               scaleExtent = [1, 8],
                                               translateExtent,
                                               borderColor = 'black',
                                               borderWidth = 0,
                                               margin = { top: 0, right: 0, bottom: 0, left: 0 }
                                           }) => {
    const svgRef = useRef<SVGSVGElement | null>(null);

    // Function to get the default translate extent with a guaranteed correct type
    const getDefaultTranslateExtent = (width: number, height: number): [[number, number], [number, number]] => {
        return [[-100, -100], [width + 100, height + 100]];
    };

    // Memoize the default translate extent to prevent unnecessary re-renders
    const defaultTranslateExtent = useMemo(() => getDefaultTranslateExtent(width, height), [width, height]);

    // Use translateExtent or defaultTranslateExtent, with a type assertion
    const actualTranslateExtent = translateExtent ?? defaultTranslateExtent;

    // Memoize zoom behavior to maintain its identity unless specific inputs change
    const zoomBehavior = useMemo(() => {
        return d3.zoom<SVGSVGElement, unknown>()
            .scaleExtent(scaleExtent)
            .translateExtent(actualTranslateExtent)
            .on("zoom", (event) => {
                const zoomGroup = d3.select(svgRef.current).select<SVGGElement>('.zoom-g');
                zoomGroup.attr('transform', event.transform.toString());
            });
    }, [scaleExtent, actualTranslateExtent]);

    useEffect(() => {
        if (svgRef.current) {
            const svgElement: Selection<SVGSVGElement, unknown, null, undefined> = d3.select(svgRef.current);
            // Apply the memoized zoom behavior to the svg element
            svgElement.call(zoomBehavior);
        }
    }, [zoomBehavior]);

    return (
        <svg ref={svgRef} width={width} height={height}>
            <rect width={width} height={height} fill="none" pointerEvents="all" />
            <g className="zoom-g" transform={`translate(${margin.left},${margin.top})`}>
                {children}
            </g>
            <rect
                x={0.5}
                y={0.5}
                width={width - 1}
                height={height - 1}
                fill="none"
                stroke={borderColor}
                strokeWidth={borderWidth}
                pointerEvents="none"
            />
        </svg>
    );
};

export default ZoomInDepth;
