import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsTools } from "react-icons/bs";
import {BiAccessibility, BiBody, BiLogOut} from "react-icons/bi";

import * as apiAuth from "../../api/auth";

interface SidebarProps {
  setPageID: (id: number) => void;
}

const Sidebar = ({ setPageID }: SidebarProps) => {
  const navigate = useNavigate();

  const [menuItemID, setMenuItemID] = useState(1);

  const onClickMenu = (id: number) => {
    setPageID(id);
    setMenuItemID(id);
  };

  const onClickLogout = async () => {
    try {
      await apiAuth.logout();
    } catch (err) {
      //console.log(err);
    }

    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="flex flex-col h-screen px-2 bg-sky-900 shadow">
      <div className="space-y-3 p-2">
        <div className="flex items-center">
          <h2 className="text-xl font-bold text-white pt-5">Admin Dashboard</h2>
        </div>
        <div className="flex-1">
          <ul className="pt-2 pb-4 space-y-1 text-sm">
            <li
              className={
                `rounded-full text-white hover:bg-orange-700 hover:cursor-pointer` +
                " " +
                (menuItemID === 1 ? "bg-orange-700" : "bg-orange-500")
              }
              onClick={() => onClickMenu(1)}
            >
              <button
                type="button"
                className="flex items-center py-2 px-4 space-x-3 rounded-md"
              >
                <BsTools className="w-4 h-4" />
                <span>Update Database</span>
              </button>
            </li>
            <li
                className={
                    `rounded-full text-white hover:bg-orange-700 hover:cursor-pointer` +
                    " " +
                    (menuItemID === 2 ? "bg-orange-700" : "bg-orange-500")
                }
                onClick={() => onClickMenu(2)}
            >
              <button
                  type="button"
                  className="flex items-center py-2 px-4 space-x-3 rounded-md"
              >
                <BiBody className="w-4 h-4" />
                <span>Users</span>
              </button>
            </li>
            <li
                className={
                    `rounded-full text-white hover:bg-orange-700 hover:cursor-pointer` +
                    " " +
                    (menuItemID === 3 ? "bg-orange-700" : "bg-orange-500")
                }
                onClick={() => onClickMenu(3)}
            >
              <button
                  type="button"
                  className="flex items-center py-2 px-4 space-x-3 rounded-md"
              >
                <BiBody className="w-4 h-4" />
                <span>Access Control</span>
              </button>
            </li>
            <li
              className={`rounded-full text-white bg-orange-500 hover:bg-orange-700 hover:cursor-pointer`}
              onClick={onClickLogout}
            >
              <button
                type="button"
                className="flex items-center py-2 px-3 space-x-3 rounded-md"
              >
                <BiLogOut className="w-5 h-5" />
                <span>Logout</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
