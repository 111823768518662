import { ElementData } from "../../_model";

export const elements: ElementData[] = [
  {
    atomicNum: 1,
    name: "Hydrogen",
    symbol: "H",
    category: "diatomic nonmetal",
  },
  { atomicNum: 2, name: "Helium", symbol: "He", category: "noble gas" },
  { atomicNum: 3, name: "Lithium", symbol: "Li", category: "alkali metal" },
  {
    atomicNum: 4,
    name: "Beryllium",
    symbol: "Be",
    category: "alkaline earth metal",
  },
  { atomicNum: 5, name: "Boron", symbol: "B", category: "metalloid" },
  {
    atomicNum: 6,
    name: "Carbon",
    symbol: "C",
    category: "polyatomic nonmetal",
  },
  {
    atomicNum: 7,
    name: "Nitrogen",
    symbol: "N",
    category: "diatomic nonmetal",
  },
  { atomicNum: 8, name: "Oxygen", symbol: "O", category: "diatomic nonmetal" },
  {
    atomicNum: 9,
    name: "Fluorine",
    symbol: "F",
    category: "diatomic nonmetal",
  },
  { atomicNum: 10, name: "Neon", symbol: "Ne", category: "noble gas" },
  { atomicNum: 11, name: "Sodium", symbol: "Na", category: "alkali metal" },
  {
    atomicNum: 12,
    name: "Magnesium",
    symbol: "Mg",
    category: "alkaline earth metal",
  },
  {
    atomicNum: 13,
    name: "Aluminum",
    symbol: "Al",
    category: "post-transition metal",
  },
  { atomicNum: 14, name: "Silicon", symbol: "Si", category: "metalloid" },
  {
    atomicNum: 15,
    name: "Phosphorus",
    symbol: "P",
    category: "polyatomic nonmetal",
  },
  {
    atomicNum: 16,
    name: "Sulfur",
    symbol: "S",
    category: "polyatomic nonmetal",
  },
  {
    atomicNum: 17,
    name: "Chlorine",
    symbol: "Cl",
    category: "diatomic nonmetal",
  },
  { atomicNum: 18, name: "Argon", symbol: "Ar", category: "noble gas" },
  { atomicNum: 19, name: "Potassium", symbol: "K", category: "alkali metal" },
  {
    atomicNum: 20,
    name: "Calcium",
    symbol: "Ca",
    category: "alkaline earth metal",
  },
  {
    atomicNum: 21,
    name: "Scandium",
    symbol: "Sc",
    category: "transition metal",
  },
  {
    atomicNum: 22,
    name: "Titanium",
    symbol: "Ti",
    category: "transition metal",
  },
  {
    atomicNum: 23,
    name: "Vanadium",
    symbol: "V",
    category: "transition metal",
  },
  {
    atomicNum: 24,
    name: "Chromium",
    symbol: "Cr",
    category: "transition metal",
  },
  {
    atomicNum: 25,
    name: "Manganese",
    symbol: "Mn",
    category: "transition metal",
  },
  { atomicNum: 26, name: "Iron", symbol: "Fe", category: "transition metal" },
  { atomicNum: 27, name: "Cobalt", symbol: "Co", category: "transition metal" },
  { atomicNum: 28, name: "Nickel", symbol: "Ni", category: "transition metal" },
  { atomicNum: 29, name: "Copper", symbol: "Cu", category: "transition metal" },
  { atomicNum: 30, name: "Zinc", symbol: "Zn", category: "transition metal" },
  {
    atomicNum: 31,
    name: "Gallium",
    symbol: "Ga",
    category: "post-transition metal",
  },
  { atomicNum: 32, name: "Germanium", symbol: "Ge", category: "metalloid" },
  { atomicNum: 33, name: "Arsenic", symbol: "As", category: "metalloid" },
  {
    atomicNum: 34,
    name: "Selenium",
    symbol: "Se",
    category: "polyatomic nonmetal",
  },
  {
    atomicNum: 35,
    name: "Bromine",
    symbol: "Br",
    category: "diatomic nonmetal",
  },
  { atomicNum: 36, name: "Krypton", symbol: "Kr", category: "noble gas" },
  { atomicNum: 37, name: "Rubidium", symbol: "Rb", category: "alkali metal" },
  {
    atomicNum: 38,
    name: "Strontium",
    symbol: "Sr",
    category: "alkaline earth metal",
  },
  { atomicNum: 39, name: "Yttrium", symbol: "Y", category: "transition metal" },
  {
    atomicNum: 40,
    name: "Zirconium",
    symbol: "Zr",
    category: "transition metal",
  },
  {
    atomicNum: 41,
    name: "Niobium",
    symbol: "Nb",
    category: "transition metal",
  },
  {
    atomicNum: 42,
    name: "Molybdenum",
    symbol: "Mo",
    category: "transition metal",
  },
  {
    atomicNum: 43,
    name: "Technetium",
    symbol: "Tc",
    category: "transition metal",
  },
  {
    atomicNum: 44,
    name: "Ruthenium",
    symbol: "Ru",
    category: "transition metal",
  },
  {
    atomicNum: 45,
    name: "Rhodium",
    symbol: "Rh",
    category: "transition metal",
  },
  {
    atomicNum: 46,
    name: "Palladium",
    symbol: "Pd",
    category: "transition metal",
  },
  { atomicNum: 47, name: "Silver", symbol: "Ag", category: "transition metal" },
  {
    atomicNum: 48,
    name: "Cadmium",
    symbol: "Cd",
    category: "transition metal",
  },
  {
    atomicNum: 49,
    name: "Indium",
    symbol: "In",
    category: "post-transition metal",
  },
  {
    atomicNum: 50,
    name: "Tin",
    symbol: "Sn",
    category: "post-transition metal",
  },
  { atomicNum: 51, name: "Antimony", symbol: "Sb", category: "metalloid" },
  { atomicNum: 52, name: "Tellurium", symbol: "Te", category: "metalloid" },
  { atomicNum: 53, name: "Iodine", symbol: "I", category: "diatomic nonmetal" },
  { atomicNum: 54, name: "Xenon", symbol: "Xe", category: "noble gas" },
  { atomicNum: 55, name: "Cesium", symbol: "Cs", category: "alkali metal" },
  {
    atomicNum: 56,
    name: "Barium",
    symbol: "Ba",
    category: "alkaline earth metal",
  },
  { atomicNum: 57, name: "Lanthanum", symbol: "La", category: "lanthanide" },
  { atomicNum: 58, name: "Cerium", symbol: "Ce", category: "lanthanide" },
  { atomicNum: 59, name: "Praseodymium", symbol: "Pr", category: "lanthanide" },
  { atomicNum: 60, name: "Neodymium", symbol: "Nd", category: "lanthanide" },
  { atomicNum: 61, name: "Promethium", symbol: "Pm", category: "lanthanide" },
  { atomicNum: 62, name: "Samarium", symbol: "Sm", category: "lanthanide" },
  { atomicNum: 63, name: "Europium", symbol: "Eu", category: "lanthanide" },
  { atomicNum: 64, name: "Gadolinium", symbol: "Gd", category: "lanthanide" },
  { atomicNum: 65, name: "Terbium", symbol: "Tb", category: "lanthanide" },
  { atomicNum: 66, name: "Dysprosium", symbol: "Dy", category: "lanthanide" },
  { atomicNum: 67, name: "Holmium", symbol: "Ho", category: "lanthanide" },
  { atomicNum: 68, name: "Erbium", symbol: "Er", category: "lanthanide" },
  { atomicNum: 69, name: "Thulium", symbol: "Tm", category: "lanthanide" },
  { atomicNum: 70, name: "Ytterbium", symbol: "Yb", category: "lanthanide" },
  { atomicNum: 71, name: "Lutetium", symbol: "Lu", category: "lanthanide" },
  {
    atomicNum: 72,
    name: "Hafnium",
    symbol: "Hf",
    category: "transition metal",
  },
  {
    atomicNum: 73,
    name: "Tantalum",
    symbol: "Ta",
    category: "transition metal",
  },
  { atomicNum: 74, name: "Wolfram", symbol: "W", category: "transition metal" },
  {
    atomicNum: 75,
    name: "Rhenium",
    symbol: "Re",
    category: "transition metal",
  },
  { atomicNum: 76, name: "Osmium", symbol: "Os", category: "transition metal" },
  {
    atomicNum: 77,
    name: "Iridium",
    symbol: "Ir",
    category: "transition metal",
  },
  {
    atomicNum: 78,
    name: "Platinum",
    symbol: "Pt",
    category: "transition metal",
  },
  { atomicNum: 79, name: "Gold", symbol: "Au", category: "transition metal" },
  {
    atomicNum: 80,
    name: "Mercury",
    symbol: "Hg",
    category: "transition metal",
  },
  {
    atomicNum: 81,
    name: "Thallium",
    symbol: "Tl",
    category: "post-transition metal",
  },
  {
    atomicNum: 82,
    name: "Lead",
    symbol: "Pb",
    category: "post-transition metal",
  },
  {
    atomicNum: 83,
    name: "Bismuth",
    symbol: "Bi",
    category: "post-transition metal",
  },
  {
    atomicNum: 84,
    name: "Polonium",
    symbol: "Po",
    category: "post-transition metal",
  },
  { atomicNum: 85, name: "Astatine", symbol: "At", category: "metalloid" },
  { atomicNum: 86, name: "Radon", symbol: "Rn", category: "noble gas" },
  { atomicNum: 87, name: "Francium", symbol: "Fr", category: "alkali metal" },
  {
    atomicNum: 88,
    name: "Radium",
    symbol: "Ra",
    category: "alkaline earth metal",
  },
  { atomicNum: 89, name: "Actinium", symbol: "Ac", category: "actinide" },
  { atomicNum: 90, name: "Thorium", symbol: "Th", category: "actinide" },
  { atomicNum: 91, name: "Protactinium", symbol: "Pa", category: "actinide" },
  { atomicNum: 92, name: "Uranium", symbol: "U", category: "actinide" },
  { atomicNum: 93, name: "Neptunium", symbol: "Np", category: "actinide" },
  { atomicNum: 94, name: "Plutonium", symbol: "Pu", category: "actinide" },
  { atomicNum: 95, name: "Americium", symbol: "Am", category: "actinide" },
  { atomicNum: 96, name: "Curium", symbol: "Cm", category: "actinide" },
  { atomicNum: 97, name: "Berkelium", symbol: "Bk", category: "actinide" },
  { atomicNum: 98, name: "Californium", symbol: "Cf", category: "actinide" },
  { atomicNum: 99, name: "Einsteinium", symbol: "Es", category: "actinide" },
  { atomicNum: 100, name: "Fermium", symbol: "Fm", category: "actinide" },
  { atomicNum: 101, name: "Mendelevium", symbol: "Md", category: "actinide" },
  { atomicNum: 102, name: "Nobelium", symbol: "No", category: "actinide" },
  { atomicNum: 103, name: "Lawrencium", symbol: "Lr", category: "actinide" },
  {
    atomicNum: 104,
    name: "Rutherfordium",
    symbol: "Rf",
    category: "transition metal",
  },
  {
    atomicNum: 105,
    name: "Dubnium",
    symbol: "Db",
    category: "transition metal",
  },
  {
    atomicNum: 106,
    name: "Seaborgium",
    symbol: "Sg",
    category: "transition metal",
  },
  {
    atomicNum: 107,
    name: "Bohrium",
    symbol: "Bh",
    category: "transition metal",
  },
  {
    atomicNum: 108,
    name: "Hassium",
    symbol: "Hs",
    category: "transition metal",
  },
  { atomicNum: 109, name: "Meitnerium", symbol: "Mt", category: "unknown" },
  { atomicNum: 110, name: "Darmstadtium", symbol: "Ds", category: "unknown" },
  { atomicNum: 111, name: "Roentgenium", symbol: "Rg", category: "unknown" },
  {
    atomicNum: 112,
    name: "Copernicium",
    symbol: "Cn",
    category: "transition metal",
  },
  { atomicNum: 113, name: "Nihonium", symbol: "Nh", category: "unknown" },
  {
    atomicNum: 114,
    name: "Flerovium",
    symbol: "Fl",
    category: "post-transition metal",
  },
  { atomicNum: 115, name: "Moscovium", symbol: "Mc", category: "unknown" },
  { atomicNum: 116, name: "Livermorium", symbol: "Lv", category: "unknown" },
  { atomicNum: 117, name: "Tennessine", symbol: "Ts", category: "unknown" },
  { atomicNum: 118, name: "Oganesson", symbol: "Og", category: "unknown" },
];
