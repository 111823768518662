import { ElementData } from "../../../_model";
import {useState} from "react";

interface ElementProps {
  data: ElementData;
  onClickElement: (symbol: string) => void;
  showInfo: (atomicNum: number) => void;
  enabled: boolean;
  active: boolean;
  setSelectedSymbol: (symbol: string) => void;
  setShowOxidesX: (x: number) => void;
  setShowOxidesY: (y: number) => void;
  setShowOxides: (show: boolean) => void;
  setOxides: (oxides: string[]) => void;
  enabledElmOxides: Record<string, string[]>;
}

export default function Element({
  data,
  onClickElement,
  showInfo,
  enabled,
  active,
  setSelectedSymbol,
  setShowOxidesX,
  setShowOxidesY,
  setShowOxides,
  setOxides,
 enabledElmOxides,
}: ElementProps) {

  const handClickElement = (event: React.MouseEvent) => {
    if (enabled) {
      const { clientX, clientY } = event;
      setShowOxidesX(clientX);
      setShowOxidesY(clientY);
      setShowOxides(true);
      setSelectedSymbol(data.symbol);
      setOxides(enabledElmOxides[data.symbol] || []);
    }
  }

  return (
    <div
      className={`element element-${data.atomicNum} ${data.category} ${
        enabled ? "" : "disabled"
      } ${active ? "active-element" : ""}`}
      onClick={handClickElement}
    >
      <div className="text-center min-w-[1.5rem]">{data.symbol}</div>
    </div>
  );
}
