import * as React from 'react';
import Button from '@mui/material/Button';

interface ContainedButtonsProps {
    action?: string;
    onSubmit?: () => void;
    disabled?: boolean;

}

export default function ContainedButtons({action, onSubmit, disabled}: ContainedButtonsProps = {action: "submit", disabled: true}) {
    return (
        <Button
            variant="contained"
            className={"mt-1 mb-1"}
            onClick={onSubmit}
            disabled={disabled}
        >
            {action}
        </Button>
    );
}
