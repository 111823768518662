import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Data } from './types';
import { SecondaryRoles } from '../../Constants/base';

interface EditDialogProps {
    open: boolean;
    onClose: () => void;
    data: Data;
    onSave: (data: Data) => void;
}

const EditDialog: React.FC<EditDialogProps> = ({ open, onClose, data, onSave }) => {
    const [selectedRoles, setSelectedRoles] = React.useState<string[]>(data.secondaryRoles);

    const handleRoleChange = (role: string) => {
        setSelectedRoles((prev) =>
            prev.includes(role) ? prev.filter((r) => r !== role) : [...prev, role]
        );
    };

    const handleSave = () => {
        onSave({ ...data, secondaryRoles: selectedRoles });
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Edit Secondary Roles</DialogTitle>
            <DialogContent>
                {SecondaryRoles.map((role) => (
                    <FormControlLabel
                        key={role.value}
                        control={
                            <Checkbox
                                checked={selectedRoles.includes(role.label)}
                                onChange={() => handleRoleChange(role.label)}
                            />
                        }
                        label={role.label}
                    />
                ))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditDialog;
