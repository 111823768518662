// MainComponent.tsx
import React, {useEffect, useMemo, useState} from 'react';
import * as d3 from 'd3';
import { Scatterplot } from './Scatterplot';
import FilterControls from './FilterControls';
import {FilterToggle} from '../../Atoms/FilterToggle'; // Import the new component
import { DataPoint } from './types';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

interface MainComponentProps {
    initialData: DataPoint[];
    width: number;
    height: number;

    x_axis_label: string;
    x_axis_unit_label?: string;
    y_axis_label: string;

    y_axis_unit_label?: string;
}

export const MainComponent = ({ initialData, width, height, x_axis_label, y_axis_label, x_axis_unit_label, y_axis_unit_label }: MainComponentProps) => {

    const [filter, setFilter] = useState<{ xMin: number, xMax: number, yMin: number, yMax: number }>({
        xMin: 0,
        xMax: 0,
        yMin: 0,
        yMax: 0
    });

    const { initialXMin, initialXMax, initialYMin, initialYMax } = useMemo(() => ({
        initialXMin: d3.min(initialData, d => d.x) ?? 0,
        initialXMax: d3.max(initialData, d => d.x) ?? 0,
        initialYMin: d3.min(initialData, d => d.y) ?? 0,
        initialYMax: d3.max(initialData, d => d.y) ?? 0

    }), [initialData]);

    useEffect(() => {
        setFilter({
            xMin: initialXMin,
            xMax: initialXMax,
            yMin: initialYMin,
            yMax: initialYMax
        });
    }, [initialData]);

    const [xAxisUnitLabel, setXAxisUnitLabel] = useState<string | undefined>();
    const [yAxisUnitLabel, setYAxisUnitLabel] = useState<string | undefined>();

    useEffect(() => {
        setXAxisUnitLabel(x_axis_label+x_axis_unit_label);
        setYAxisUnitLabel(y_axis_label+y_axis_unit_label);
    }, [x_axis_unit_label, y_axis_unit_label, x_axis_label, y_axis_label]);

    const [filterEnabled, setFilterEnabled] = useState<boolean>(true);
    const [showFilters, setShowFilters] = useState<boolean>(false);

    const filteredData = useMemo(() => {
        if (filterEnabled) {
            return initialData.filter(d =>
                d.x >= filter.xMin && d.x <= filter.xMax &&
                d.y >= filter.yMin && d.y <= filter.yMax
            );
        }
        return initialData;
    }, [filterEnabled, initialData, filter]);

    const handleFilterChange = (newFilter: { xMin: number, xMax: number, yMin: number, yMax: number }) => {
        if (newFilter.xMin <= newFilter.xMax && newFilter.yMin <= newFilter.yMax) {
            setFilter(newFilter);
        } else {
            alert("Minimum value cannot be greater than maximum value for either axis.");
        }
    };

    return (
        <Box>
            {filterEnabled && (
                <FilterToggle showFilters={showFilters} setShowFilters={setShowFilters}>
                    <FilterControls
                        onFilterChange={handleFilterChange}
                        xMinValue={filter.xMin}
                        xMaxValue={filter.xMax}
                        yMinValue={filter.yMin}
                        yMaxValue={filter.yMax}
                        enabled={filterEnabled}
                    />
                </FilterToggle>
            )}
            <Scatterplot
                width={width}
                height={height}
                data={initialData}
                filteredData={filteredData}
                xAxisLabel={xAxisUnitLabel}
                yAxisLabel={yAxisUnitLabel}
                zoomWithAxisEnabled={false}
                filterEnabled={filterEnabled}
            />
        </Box>
    );
};

