import React from 'react';
import { CustomInput } from '../../Atoms/CustomInput'; // Ensure you import CustomInput
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

interface FilterControlsProps {
    onFilterChange: (filter: { xMin: number, xMax: number, yMin: number, yMax: number }) => void;
    xMinValue: number;
    xMaxValue: number;
    yMinValue: number;
    yMaxValue: number;
    enabled: boolean;
}

const FilterControls: React.FC<FilterControlsProps> = ({
                                                           onFilterChange,
                                                           xMinValue,
                                                           xMaxValue,
                                                           yMinValue,
                                                           yMaxValue,
                                                           enabled,
                                                       }) => {
    if (!enabled) {
        return null;
    }

    return (
        <Box sx={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', marginBottom: 2 }}>
            <Typography variant="subtitle1" sx={{ marginY: 2 }}>
                Filter Data by X and Y Values
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="x-min">X Min</InputLabel>
                        <CustomInput
                            id="x-min"
                            type="number"
                            value={xMinValue}
                            onChange={(e) => onFilterChange({
                                xMin: Number(e.target.value),
                                xMax: xMaxValue,
                                yMin: yMinValue,
                                yMax: yMaxValue,
                            })}
                            aria-describedby="x-min-text"
                            placeholder="Min X Value"
                        />
                        {/*<FormHelperText id="x-min-text">Minimum X Value</FormHelperText>*/}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="x-max">X Max</InputLabel>
                        <CustomInput
                            id="x-max"
                            type="number"
                            value={xMaxValue}
                            onChange={(e) => onFilterChange({
                                xMin: xMinValue,
                                xMax: Number(e.target.value),
                                yMin: yMinValue,
                                yMax: yMaxValue,
                            })}
                            aria-describedby="x-max-text"
                            placeholder="Max X Value"
                        />
                        {/*<FormHelperText id="x-max-text">Maximum X Value</FormHelperText>*/}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="y-min">Y Min</InputLabel>
                        <CustomInput
                            id="y-min"
                            type="number"
                            value={yMinValue}
                            onChange={(e) => onFilterChange({
                                xMin: xMinValue,
                                xMax: xMaxValue,
                                yMin: Number(e.target.value),
                                yMax: yMaxValue,
                            })}
                            aria-describedby="y-min-text"
                            placeholder="Min Y Value"
                        />
                        {/*<FormHelperText id="y-min-text">Minimum Y Value</FormHelperText>*/}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <InputLabel shrink htmlFor="y-max">Y Max</InputLabel>
                        <CustomInput
                            id="y-max"
                            type="number"
                            value={yMaxValue}
                            onChange={(e) => onFilterChange({
                                xMin: xMinValue,
                                xMax: xMaxValue,
                                yMin: yMinValue,
                                yMax: Number(e.target.value),
                            })}
                            aria-describedby="y-max-text"
                            placeholder="Max Y Value"
                        />
                        {/*<FormHelperText id="y-max-text">Maximum Y Value</FormHelperText>*/}
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FilterControls;
