import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Layout from "./components/Layout";
import Error from "./components/Error";
import Welcome from "./components/Landing";
import Profile from "./components/Profile";
import NewsUpdates from "./components/NewsUpdates";
import Database from "./components/Database/Database";
import Admin from "./components/Admin/Admin";

import LoginV1 from "./components/Auth/Login"
import RegisterV1 from "./components/Auth/Register"
import ForgotPassword from "./components/Auth/ForgotPassword"
import ResetPassword from "./components/Auth/ResetPassword"
import VerificationPage from "./components/Auth/VerificationPage"
import RegistrationSuccessPage from "./components/Auth/RegistrationSuccessPage"
import GlassPyMain from "./components/GlassPy/GlassPyMain";
import ContactUs from "./components/ContactUs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      { path: "", element: <Welcome /> },
      { path: "database", element: <Database /> },
      { path: "glasspy", element: <GlassPyMain /> },
      {path: "contact-us", element: <ContactUs />},
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/news-updates",
        element: <NewsUpdates />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginV1 />,
    errorElement: <Error />,
  },
  {
    path: "/register",
    element: <RegisterV1 />,
    errorElement: <Error />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    errorElement: <Error />,
  },
  {
    path: "/reset-password/:hash",
    element: <ResetPassword />,
    errorElement: <Error />,
  },
  {
    path: "/verifyemail/:hash",
    element: <VerificationPage />,
    errorElement: <Error />,
  },
  {
    path: "/registration-success",
    element: <RegistrationSuccessPage />,
    errorElement: <Error />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
