import React, {useState, useEffect, useRef} from 'react';
import {Dialog, DialogContent, LinearProgress, MenuItem, Select} from "@mui/material";
import parse from "html-react-parser";
import {ElementFilter, NumberRange, OxideFilter} from "../Database";
import * as apiDatabase from "../../../api/database";
import {queryWithParameter} from "../../../api/database";
import {CSVtoArray, formatSubscriptWithHTML} from "../../../utils";
import CircularProgress from '@mui/material/CircularProgress';
import {MainComponent, Scatterplot} from "../Scatterplot";
import {DatabaseOptions} from "../../Constants/base";
import {string} from "prop-types";
const selectStyle = {
    minWidth: '150px', // Set your desired minimum width here
    margin: '1rem'
};

const ScatterPlotWrapper = ({result, activeProperties, propertiesToValueRange, selectedElmsToFilter, selectedOxidesToFilter, selectedDb}) => {

    const [data, setData] = useState([]);

    const [scatterPlotData, setScatterPlotData] = useState([]);
    const [selectedXAxis, setSelectedXAxis] = useState('');
    const [selectedYAxis, setSelectedYAxis] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const [xAxisUoM, setXAxisUoM] = useState('');
    const [yAxisUoM, setYAxisUoM] = useState('');

    const [propertiesFilter, setPropertiesFilter] = useState([]);

    const [downloadProgress, setDownloadProgress] = useState(0);

    const graphRef = useRef(null); // Ref for the graph component

    function handleYAxisChange(event) {
        setSelectedYAxis(event.target.value);
    }

    function handleXAxisChange(event) {
        setSelectedXAxis(event.target.value);
    }

    const cleanup = () => {
        setSelectedYAxis('');
        setSelectedXAxis('');
        setData([]);
        setScatterPlotData([]);
    }

    // Simulate fetching data
    useEffect(() => {
        cleanup()
        const fetchData = async () => {
            setIsLoading(true);
            setDownloadProgress(0);
            try {

                const propertiesToValueRangePayload = new Map();

                // Fill the map with default or existing value ranges
                activeProperties.forEach(p => {
                    // Assuming propertiesToValueRange is available in the context
                    let vRange = propertiesToValueRange.get(p);
                    if (!vRange) vRange = { from: 0, to: 0 };
                    propertiesToValueRangePayload.set(p, vRange);
                });

                const res = await apiDatabase.FetchAxis(
                    selectedElmsToFilter,
                    propertiesToValueRangePayload,
                    0,
                    20,
                    selectedDb,
                    [selectedXAxis, selectedYAxis]
                );

                if (res && res.data && res.data.length > 0) {

                    // Ensure that Details, Compositions, and Properties are arrays or default to empty arrays
                    const details = Array.isArray(res.data[0]['Details']) ? res.data[0]['Details'] : [];
                    const compositions = Array.isArray(res.data[0]['Compositions']) ? res.data[0]['Compositions'] : [];
                    const propertiesList = Array.isArray(res.data[0]['Properties']) ? res.data[0]['Properties'] : [];

                    // Combine all properties into one array
                    const properties = [...compositions, ...propertiesList];

                    //console.log("properties : ", properties);
                    const propertiesFilter = properties.map((property) => ({

                        key: property['Label'],
                        value: property['Label'],
                        uom : property['UoM'],
                    }));

                    setPropertiesFilter(propertiesFilter);
                }


                setIsLoading(false);
                setDownloadProgress(100);
                graphRef.current.scrollIntoView({ behavior: 'smooth' });
            } catch (error) {
                //console.error("Failed to fetch data:", error);
                setIsLoading(false);
                setDownloadProgress(100);
            }
        };

        fetchData().then();


    }, [selectedDb, result]);

    useEffect( () => {

        // Function to fetch data
        const fetchData = async () => {
            setData([])
            setIsLoading(true);
            // Create a map to hold the value ranges
            const propertiesToValueRangePayload = new Map();

            // Fill the map with default or existing value ranges
            activeProperties.forEach(p => {
                // Assuming propertiesToValueRange is available in the context
                let vRange = propertiesToValueRange.get(p);
                if (!vRange) vRange = { from: 0, to: 0 };
                propertiesToValueRangePayload.set(p, vRange);
            });

            try {

              // Combine both selectedElmsToFilter and selectedOxidesToFilter
                const combinedFilters = new Map([
                    ...selectedElmsToFilter,
                    ...selectedOxidesToFilter,
                ]);

                // Perform the API query
                const res = await apiDatabase.queryWithParameter(
                    combinedFilters,
                    propertiesToValueRangePayload,
                    0,
                    20,
                    selectedDb,
                    [selectedXAxis, selectedYAxis]
                );

                //console.log(res, [selectedXAxis, selectedYAxis])

                const parsed = []; // Initialize an empty array

                for (const line of res.data.split("\n")) {
                    const cols = CSVtoArray(line); // Assume CSVtoArray properly parses a CSV line into an array
                    if (cols.length > 1) parsed.push(cols); // Only push rows with more than one column
                }

                const graphData = transformData(parsed, selectedXAxis, selectedYAxis);
                setData(graphData);
                setScatterPlotData(graphData);

                //console.log("graphData : ", graphData);

                setIsLoading(false);
            } catch (error) {
                //console.error("Failed to fetch data:", error);
                setIsLoading(false);
            }
        };

        if (selectedXAxis && selectedYAxis) {
            fetchData().then(() =>{

                let selectedProperty = propertiesFilter.find((property) => property.key === selectedXAxis);
                if (selectedProperty)
                    setXAxisUoM(selectedProperty.uom ? ` (${selectedProperty.uom})` : '');
                else
                    setXAxisUoM('');

                selectedProperty = propertiesFilter.find((property) => property.key === selectedYAxis);
                if (selectedProperty)
                    setYAxisUoM(selectedProperty.uom ? ` (${selectedProperty.uom})` : '');
                else
                    setYAxisUoM('');

            });
        }
    }, [selectedXAxis, selectedYAxis]);

    const transformData = (result, xHeader, yHeader) => {
        const headers = result[0]; // First element as headers
        const rows = result.slice(1); // Remaining elements as rows

        // Normalize headers and search headers to lowercase for comparison
        const normalizedHeaders = headers.map(header => header.toLowerCase());
        const normalizedXHeader = xHeader.toLowerCase();
        const normalizedYHeader = yHeader.toLowerCase();

        // Find the indices for x and y based on normalized headers
        const xIndex = normalizedHeaders.findIndex(header => header.startsWith(normalizedXHeader));
        const yIndex = normalizedHeaders.findIndex(header => header.startsWith(normalizedYHeader));

        if (xIndex === -1 || yIndex === -1) {
            return [];
        }

        // Map over the rows to create the data structure
        return rows.map((row, index) => {
            const xValue = Number(row[xIndex]);
            const yValue = Number(row[yIndex]);

            // Check if the x or y value is NaN, or if both are zero
            if (isNaN(xValue) || isNaN(yValue) || (xValue === 0 && yValue === 0)) {
                return null; // Return null for invalid rows
            }

            return {
                group: `group ${index + 1}`,
                x: xValue,
                y: yValue,
            };
        }).filter(Boolean); // Filter out any null entries
    };


    return (
        <div className="flex flex-col justify-center items-center gap-2 rounded-md border-2 w-full mt-2">
            <div className="flex justify-center items-center w-full" style={{ background: "#03457b" }}>
                <h2 style={{ fontWeight: 'bold', fontSize: "1.5rem", color: "white"}}>Data Visualization Chart</h2>
            </div>
            <div className="flex justify-center items-center w-full gap-2" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ marginRight: '1rem' }}>
                    <span style={{ fontWeight: 'bold' }}>X-axis:</span>
                    <Select label="X-axis" value={selectedXAxis} onChange={handleXAxisChange} style={selectStyle}>
                        {propertiesFilter && propertiesFilter.map((property) => (
                            <MenuItem key={property.key} value={property.key} disabled={property.key === selectedYAxis}>
                                {parse(formatSubscriptWithHTML(property.key))}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div>
                    <span style={{ fontWeight: 'bold' }}>Y-axis:</span>
                    <Select label="Y-axis" value={selectedYAxis} onChange={handleYAxisChange} style={selectStyle}>
                        {propertiesFilter && propertiesFilter.map((property) => (
                            <MenuItem key={property.key} value={property.key} disabled={property.key === selectedXAxis}>
                                {parse(formatSubscriptWithHTML(property.key))}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-2 p-4">
                <Dialog open={isLoading} onClose={() => {}} fullWidth maxWidth="sm" >
                    <DialogContent className="flex flex-col items-center p-5">
                        <div className="w-full mb-4">
                            <LinearProgress variant="determinate" value={downloadProgress} />
                        </div>
                        <p className="text-center">Fetching data... {downloadProgress}%</p>
                    </DialogContent>
                </Dialog>

                {data && data.length > 0 && !isLoading ? (
                        <>
                            <div  className="flex justify-center items-center h-full gap-2 p-2" style={{ border: '2px solid #ccc', borderRadius: '10px' }}>
                                <MainComponent
                                    initialData={data}
                                    width={800}
                                    height={500}
                                    x_axis_label={selectedXAxis}
                                    y_axis_label={selectedYAxis}
                                    x_axis_unit_label={xAxisUoM}
                                    y_axis_unit_label={yAxisUoM}
                                />
                            </div>
                            <div ref={graphRef}></div>
                        </>
                    )
                    : <div className="flex justify-center items-center h-48 border border-gray-300 rounded-lg p-4 bg-gray-100 gap-4">
                        <p className="text-gray-700 text-center">No data to display. Please select different X and Y axis.</p>
                    </div>
                }
            </div>
        </div>);
};

export default ScatterPlotWrapper;
