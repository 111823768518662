import {useEffect, useState} from "react";
import { List, ListItem, ListItemText, Button, Box } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import * as apiUser from "../../api/user";

const getUsers = async () => {
    try {
        return await apiUser.getUsers(localStorage.getItem("email"));
    } catch (error) {
        console.error("Error fetching user data:", error);
        throw error; // Rethrow the error
    }
}

function formatDate(isoDate) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };
    const date = new Date(isoDate);
    return date.toLocaleString('en-US', options);
}

function getRandomMicrosecondTimestamp() {
    const currentTimestamp = new Date().getTime(); // Get current timestamp in milliseconds
    const randomMicroseconds = Math.floor(Math.random() * 1000000); // Generate a random number of microseconds (up to 999999)
    const randomTimestamp = currentTimestamp * 1000 + randomMicroseconds; // Convert to microseconds

    return randomTimestamp.toString();
}
export default function ManageAccounts() {

    const [users, setUsers] = useState([
        { id: 1, name: 'John Doe', email: 'john.doe@example.com', created: '2023-10-20', isEmailVerified: 0, isAdmin:0, data_access_requested_status: 0 }
    ]);

    const [currentEmail, setCurrentEmail] = useState("")

    useEffect(() => {
        getUsers()
            .then((res) => {
                //console.log("got res: ", res)
                setUsers(res.data.data);
            })
            .catch((error) => {
                // Handle the error as needed
                //console.error("Error setting user data:", error);
                setUsers([]);
            });
    }, [currentEmail]);

    const UserList = ({ users }) => {
        // const handleVerify = async (userId, email, has_data_access) => {
        //
        //     try {
        //
        //         // Implement your verification logic here
        //
        //         has_data_access = has_data_access ? 0 : 1
        //
        //         await apiUser.updateUserDataAccessPermission(has_data_access, email);
        //
        //         //Refresh the data
        //         setCurrentEmail(getRandomMicrosecondTimestamp())
        //
        //     } catch (error) {
        //         console.error("Error fetching user data:", error);
        //         throw error; // Rethrow the error
        //     }
        //
        // };

        const handleVerify = async (userId, email, data_access_requested_status) => {

            try {

                // Implement your verification logic here

                await apiUser.updateUserDataAccessPermission(data_access_requested_status, email);

                //Refresh the data
                setCurrentEmail(getRandomMicrosecondTimestamp())

            } catch (error) {
                console.error("Error fetching user data:", error);
                throw error; // Rethrow the error
            }

        };

        return (
            <div>
                <List>
                    {users && users
                        .sort((a, b) => a.email.localeCompare(b.email)) // Sort by email
                        .map((user) => (
                            <ListItem key={user.id}>
                                <ListItemText
                                    primary={user.email}
                                    secondary={
                                        <div>
                                            Email Verified: {user.isEmailVerified ? "Yes" : "No"} -
                                            Admin: {user.isAdmin ? "Yes" : "No"} -
                                            Account Request Status:
                                            {user.data_access_requested_status === undefined || user.data_access_requested_status === 0 ? "Not Requested" :
                                                user.data_access_requested_status === 1 ?
                                                    <span style={{ color: 'black' }}>Requested</span> :
                                                    user.data_access_requested_status === 2 ?
                                                        <span style={{ color: 'green' }}>Approved</span> :
                                                        <span style={{ color: 'red' }}>Declined</span>} -
                                            Created At: {formatDate(user.createdAt)}
                                        </div>
                                    }
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<CheckCircleIcon />}
                                    size="small"
                                    sx={{ fontSize: 14, width: 180 }}
                                    // onClick={() => handleVerify(user.id, user.email, user.data_access_requested_status)}
                                    onClick={() => {
                                        const newStatus = user.data_access_requested_status === 2 ? 3 : 2;
                                        handleVerify(user.id, user.email, newStatus);
                                    }}
                                >
                                    {/*{user.has_data_access ? "Disable Account" : "Allow Access"}*/}
                                    {user.data_access_requested_status === 2 ? "Decline Request" : "Approve Request"}
                                </Button>
                            </ListItem>
                        ))}
                </List>
            </div>
        );

    };

    return (
        <Box p={3} bgcolor="white">
            <h1 style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '24px'}}>User List</h1>
            <UserList users={users} />
        </Box>
    );
}
