import { useEffect, useState } from "react";
import { BsSearch, BsFillTrashFill } from "react-icons/bs";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";
import { elements } from "./_data";

import * as apiDatabase from "../../api/database";
import * as apiUser from "../../api/user";
import DataDisplayTable from "./DataDisplayTable";
import PeriodicTable from "./PeriodicTable/PeriodicTable";
import {CSVtoArray, formatSubscript, formatSubscriptWithHTML} from "../../utils";
import { PropertyData } from "../../_model";
import {useNavigate} from "react-router-dom";
import MenuSelect from "../Common/MenuSelect";
import ScatterPlotWrapper from "./Graph/ScatterPlotWrapper";
import {DatabaseOptions} from "../Constants/base";
import SnackbarNotification from "../GlassPy/SnackbarNotification";
import * as React from "react";
import {Label, LabelImportant} from "@mui/icons-material";
import parse from "html-react-parser";

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 1,
  py: 2,
  px: 4,
};

export interface NumberRange {
  from: number;
  to?: number;
}

export interface ElementFilter {
  from: number;
  to?: number;
  exclude?: boolean;
}

export interface OxideFilter {
  from: number;
  to?: number;
  exclude?: boolean;
}

export default function Database() {

  const navigate = useNavigate();

  const isLoggedIn = localStorage.getItem("email") !== null;

  const [enabledElms, setEnabledElms] = useState<number[]>([]);
  const [selectedElmsToFilter, setSelectedElmsToFilter] = useState<
    Map<string, ElementFilter>
  >(new Map());

  const [enabledElmOxides, setEnabledElmOxides] = useState<Record<string, string[]>>({});
  const [selectedOxidesToFilter, setSelectedOxidesToFilter] = useState<
      Map<string, OxideFilter>
  >(new Map());

  const [selectedElmOxide, setSelectedElmOxide] = useState<Map<string, string>>(new Map());

  const [properties, setProperties] = useState<PropertyData[]>([]);
  const [activeProperties, setActiveProperties] = useState<string[]>([]);
  const [propertiesToValueRange, setPropertiesToValueRange] = useState<
    Map<string, NumberRange>
  >(new Map());

  const [strSearch, setStrSearch] = useState("");
  const [data, setData] = useState("");
  const [parsedData, setParsedData] = useState<Array<Array<string>>>([[]]);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);

  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgComp, setErrorMsgComp] = useState("");
  const [errorMsgProp, setErrorMsgProp] = useState("");

  const [totalCount, setTotalCount] = useState(0);

  const [hasDataAccess, setHasDataAccess] = useState(0)
  const [selectedDb, setSelectedDb] = useState(1);

  const checkPermission = async () => {
    try {
      const res = await apiUser.getUserByEmail(localStorage.getItem("email") || "");
      return res.data.data.has_data_access;
    } catch (err) {
      return 0;
    }
  };


  useEffect( () => {
    checkPermission().then( res => {
      setHasDataAccess(res)
    })
    queryData();
  }, [])

  useEffect(() => {
    const populateTable = async () => {
      setIsLoading(true);
      const res = await apiDatabase.getSupportedElements(selectedDb);
      setIsLoading(false);

      if (res.error) {
        setErrorMsg(res.error.response?.data);
        return;
      }

      //console.log("res.data: ", res.data)

      res.data.elements.forEach((elm: string) => {
        const found = elements.find((e) => e.symbol === elm);
        if (found) enabledElms.push(found.atomicNum);
      });

      //console.log("res.data.elements: ", res.data.elements, res.data)

      setEnabledElms(() => enabledElms);
      setEnabledElmOxides(() => res.data.element_compositions);
    };

    if (isLoggedIn) populateTable();

    if (!isLoggedIn) navigate("/login")

  }, [isLoggedIn, selectedDb]);

  useEffect(() => {
    //if (propertiesToValueRange.size > 0) return;

    const populateProperties = async () => {
      setIsLoading(true);

      try {
        const res = await apiDatabase.getSupportedProperties(selectedDb);
        setIsLoading(false);

        if (res.error) {
          setErrorMsg(res.error.response?.data);
          return;
        }

        const updatedProperties: any[] | ((prevState: PropertyData[]) => PropertyData[]) = [];
        const updatedPropertiesToValueRange = new Map(propertiesToValueRange);

        res.data.properties.forEach(
            (property: { label: string; }) => {
              updatedProperties.push(property);
              updatedPropertiesToValueRange.set(property.label, { from: 0 });
            }
        );

        setProperties(updatedProperties);
        setPropertiesToValueRange(updatedPropertiesToValueRange);
      } catch (error) {
        setIsLoading(false);
        setErrorMsg('An unexpected error occurred.');
      }
    };

    if (isLoggedIn) {
      populateProperties();
    }
  }, [selectedDb, isLoggedIn]);

  useEffect(() => {
    const parsed: Array<Array<string>> = [];

    for (const line of data.split("\n")) {
      const cols = CSVtoArray(line);
      if (cols.length > 1) parsed.push(cols);
    }

    setParsedData(parsed);
  }, [data]);

  const onClickElement = (symbol: string) => {
    // For composition percentage filter
    if (selectedElmsToFilter.has(symbol)) {
      selectedElmsToFilter.delete(symbol);
    } else {
      selectedElmsToFilter.set(symbol, { from: 0 });
    }

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };

  const onClickOxide = (oxide: string) => {
    // For composition percentage filter
    if (selectedOxidesToFilter.has(oxide)) {
      selectedOxidesToFilter.delete(oxide);
    } else {
      selectedOxidesToFilter.set(oxide, { from: 0 });
    }

    console.log("selectedElmsToFilter: ",selectedElmsToFilter)

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };


  const onClickQuery = async () => {
    setErrorMsg("");
    setErrorMsgComp("");
    setErrorMsgProp("");

    //console.log("query database called 1")


    let sumLowerRange = 0;
    for (const [symbol, pRange] of selectedElmsToFilter) {
      const elm = elements.find((elm) => elm.symbol === symbol);
      if (!elm) return;

      if (pRange.to !== undefined) {
        if (pRange.from > pRange.to && pRange.to !== 0) {
          setErrorMsgComp(
            "Invalid " +
              elm.symbol +
              " (" +
              elm.name +
              ") percentage composition range."
          );
          return;
        }
      }
      sumLowerRange += pRange.from;
    }
    if (sumLowerRange > 100) {
      setErrorMsgComp("Composition lower bounds exceed 100%.");
      return;
    }

    for (const [label, vRange] of propertiesToValueRange) {
      if (vRange.to !== undefined) {
        if (vRange.from > vRange.to && vRange.to !== 0) {
          setErrorMsgProp("Invalid " + label + " value range.");
          return;
        }
      }
    }

    await queryData();

  };

  const queryData = async () => {

    const propertiesToValueRangePayload = new Map<string, NumberRange>();
    for (const p of activeProperties) {
      let vRange = propertiesToValueRange.get(p);
      if (!vRange) vRange = { from: 0, to: 0 };
      propertiesToValueRangePayload.set(p, vRange);
    }

    // Combine both selectedElmsToFilter and selectedOxidesToFilter
    const combinedFilters = new Map<string, ElementFilter | OxideFilter>([
      ...selectedElmsToFilter,
      ...selectedOxidesToFilter,
    ]);

    setIsLoading(true);

    //console.log("combinedFilters: ", combinedFilters, propertiesToValueRangePayload, selectedDb)

    const count = await apiDatabase.queryCount(combinedFilters, propertiesToValueRangePayload, selectedDb);

    setTotalCount(count.data.data)

    //console.log("queryData database page: ", selectedElmsToFilter, propertiesToValueRangePayload, count)

    setIsLoading(false);

  };

  const onToggleElmExclude = (symbol: string) => {
    const ft = selectedElmsToFilter.get(symbol);
    if (!ft) return;

    selectedElmsToFilter.set(symbol, {
      from: ft.from,
      to: ft.to,
      exclude: ft.exclude === true ? false : true,
    });

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };

  const onToggleOxideExclude = (oxide: string) => {
    const ft = selectedOxidesToFilter.get(oxide);
    if (!ft) return;

    selectedOxidesToFilter.set(oxide, {
      from: ft.from,
      to: ft.to,
      exclude: ft.exclude === true ? false : true,
    });

    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };

  const onChangeElmPercentFrom = (symbol: string, percentFrom: number) => {
    if (percentFrom < 0) return;
    const ft = selectedElmsToFilter.get(symbol);
    if (!ft) return;

    selectedElmsToFilter.set(symbol, { from: percentFrom, to: ft.to });
    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };

  const onChangeElmPercentTo = (symbol: string, percentTo: number) => {
    if (percentTo > 100) return;

    const ft = selectedElmsToFilter.get(symbol);
    if (!ft) return;

    selectedElmsToFilter.set(symbol, { from: ft.from, to: percentTo });
    setSelectedElmsToFilter(new Map(selectedElmsToFilter));
  };


  const onChangeOxidePercentFrom = (oxide: string, percentFrom: number) => {
    if (percentFrom < 0) return;
    const ft = selectedOxidesToFilter.get(oxide);
    if (!ft) return;

    selectedOxidesToFilter.set(oxide, { from: percentFrom, to: ft.to });
    setSelectedOxidesToFilter(new Map(selectedOxidesToFilter));
  };

  const onChangeOxidePercentTo = (oxide: string, percentTo: number) => {
    if (percentTo > 100) return;

    const ft = selectedOxidesToFilter.get(oxide);
    if (!ft) return;

    selectedOxidesToFilter.set(oxide, { from: ft.from, to: percentTo });
    setSelectedOxidesToFilter(new Map(selectedOxidesToFilter));
  };


  const onToggleProperty = (label: string) => {
    // For property value filter
    let idx = activeProperties.indexOf(label);

    if (idx === -1) {
      activeProperties.push(label);
      setActiveProperties([...activeProperties]);
      return;
    }

    activeProperties.splice(idx, 1);
    setActiveProperties([...activeProperties]);

    propertiesToValueRange.set(label, { from: 0 });
    setPropertiesToValueRange(new Map(propertiesToValueRange));
  };

  const onChangePropPercentFrom = (label: string, valueFrom: number) => {
    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    const to = propertiesToValueRange.get(label)?.to;

    // Update the range without preventing negative numbers
    propertiesToValueRange.set(label, { from: valueFrom, to: to });
    setPropertiesToValueRange(new Map(propertiesToValueRange));
  };

  const onChangePropPercentTo = (label: string, valueTo: number) => {
    if (activeProperties.indexOf(label) === -1) activeProperties.push(label);

    let from = propertiesToValueRange.get(label)?.from;
    if (from === undefined) from = 0;

    // Update the range without preventing negative numbers
    propertiesToValueRange.set(label, { from: from, to: valueTo });
    setPropertiesToValueRange(new Map(propertiesToValueRange));
  };


  const handleFocus = (e: any) => e.target.select();



  const cleanup = () => {
    setProperties([]);
    setActiveProperties([]);
    setSelectedElmsToFilter(new Map());
    setErrorMsg("");
    setErrorMsgComp("");
    setErrorMsgProp("");
    setStrSearch("");
    setData("");
    setParsedData([[]]);
    setTotalCount(0);
    setPropertiesToValueRange(new Map());
  }

  const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDbChange = async (event: any) => {
    cleanup();
    setSelectedDb(event.target.value);
  };

  useEffect(() => {
    onClickQuery().then()
  }, [selectedDb])

  return (
    <>

      {isLoading ? (
        <div className="absolute w-full h-full z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <img
            src="/loading.gif"
            alt="loading..."
            className="max-h-[25%] aspect-square"
          />
        </div>
      ) : (
        <></>
      )}


      {isLoggedIn ? (
        <>
          <div className="flex flex-col gap-2 p-2">
            <div className="flex gap-2 h-[600px] min-h-0">
              {/* Property Filter Pane */}
              <div className="w-1/5 flex flex-col gap-3 p-4 bg-neutral-100 rounded-lg">
                <h1 className="font-light text-white text-center py-1 bg-[#03457b] rounded-md text-lg">
                  Filter by Properties
                </h1>


                {/* Display error message properties */}
                {/*{errorMsgProp.length > 0 ? (*/}
                {/*  <div className="w-full text-center font-medium text-red-600 text-sm">*/}
                {/*    {errorMsgProp}*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <></>*/}
                {/*)}*/}

                <div className="flex flex-col gap-3 overflow-y-auto font-light">
                  {properties.length === 0 ? (
                    <div className="h-full flex flex-col px-4 justify-center items-center text-center font-light">
                      Collecting properties...
                    </div>
                  ) : (
                    <></>
                  )}
                  {properties && properties.map((p, _) => {
                    const isActive = activeProperties.indexOf(p.label) !== -1;

                    const vRange = {
                      from: propertiesToValueRange.get(p.label)?.from,
                      to: propertiesToValueRange.get(p.label)?.to,
                    };

                    if (vRange.from === undefined) {
                      return <></>;
                    }

                    return (
                      <div key={p.label} className="flex w-full gap-2">
                        <div
                          className={
                            "flex flex-col w-full py-3 px-2 bg-white shadow-md rounded-md" +
                            " " +
                            (isActive ? "" : "brightness-90") +
                            " " +
                            (vRange.to !== undefined
                              ? vRange.from && vRange.from > vRange.to && vRange.to > 0
                                ? "border border-red-600"
                                : ""
                              : "")
                          }
                          onFocus={handleFocus}
                        >
                          <div className="w-full flex px-2 mb-1">
                            <h1 className="w-full">
                              {p.label} {p.uom ? "(" + parse(p.uom) + ")" : ""}
                            </h1>
                            {isActive ? (
                              <div className="flex items-center gap-1">
                                <p className="text-xs min-w-max">active</p>
                                <FaToggleOn
                                  className="hover:cursor-pointer text-green-600"
                                  size={25}
                                  onClick={() => onToggleProperty(p.label)}
                                />
                              </div>
                            ) : (
                              <div className="flex items-center gap-1">
                                <p className="text-xs min-w-max">inactive</p>
                                <FaToggleOff
                                  className="hover:cursor-pointer text-red-600"
                                  size={25}
                                  onClick={() => onToggleProperty(p.label)}
                                />
                              </div>
                            )}
                          </div>
                          <div className="flex justify-center gap-2 px-2">
                            <input
                              className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                              type="number"
                              step={0.1}
                              value={!vRange.from ? "" : vRange.from}
                              onChange={(e) =>
                                onChangePropPercentFrom(
                                  p.label,
                                  Number(e.target.value)
                                )
                              }
                              onFocus={handleFocus}
                            />
                            to
                            <input
                              className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                              type="number"
                              step={0.1}
                              value={!vRange.to  ? "" : vRange.to}
                              onChange={(e) =>
                                onChangePropPercentTo(
                                  p.label,
                                  Number(e.target.value)
                                )
                              }
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Periodic Table */}
              <div className="flex flex-col justify-center items-center  w-full overflow-x-scroll">
                <div className="w-4/12">
                  <MenuSelect
                      label="Choose a database"
                      options={DatabaseOptions}
                      value={selectedDb}
                      onChange={handleDbChange}
                  />
                </div>
                <div className="flex justify-center h-full items-center whitespace-nowrap">
                  <div className="mx-auto">
                    <PeriodicTable
                        enabledElements={enabledElms}
                        enabledElmOxides={enabledElmOxides}
                        setSelectedElmOxide={setSelectedElmOxide}
                        activeElements={Array.from(selectedElmsToFilter.keys())}
                        onClickElement={onClickElement}
                        onClickOxide={onClickOxide}
                    />
                  </div>
                </div>
              </div>

              {/* Composition Filter Pane */}
              <div className="w-1/4 flex flex-col gap-3 p-4 bg-neutral-100 rounded-lg">
                <h1 className="font-light text-white text-center py-1 bg-[#03457b] rounded-md text-lg">
                  Filter by Oxides (wt%)
                </h1>

                {/* Display error message composition */}
                {errorMsgComp.length > 0 ? (
                  <div className="w-full text-center font-medium text-red-600 text-sm">
                    {errorMsgComp}
                  </div>
                ) : (
                  <></>
                )}

                <div className="flex flex-col gap-3 overflow-y-auto font-light">

                  {Array.from(selectedElmsToFilter.entries()).length === 0 ? (
                    <div className="flex px-4 text-center font-light">
                      Click an element to filter by an oxide.
                    </div>
                  ) : (
                    <></>
                  )}

                  {Array.from(selectedElmsToFilter.entries()).map(
                    ([symbol, pRange], _) => {
                      const elm = elements.find((elm) => elm.symbol === symbol);
                      if (!elm) return <></>;

                      return (
                        <div key={symbol} className="flex w-full gap-2">
                          <div
                            className={
                              "flex flex-col w-full py-3 px-2 bg-white shadow-md rounded-md" +
                              " " +
                              (pRange.to !== undefined
                                ? pRange.from > pRange.to && pRange.to > 0
                                  ? "border border-red-600"
                                  : ""
                                : "")
                            }
                            onFocus={handleFocus}
                          >
                            <div className="w-full flex px-2 mb-1 gap-2">
                              <h1 className="w-full">{elm.symbol}</h1>

                              {selectedElmsToFilter.get(elm.symbol)?.exclude ===
                              true ? (
                                <div className="flex items-center gap-1">
                                  <p className="text-xs min-w-max">exclude</p>
                                  <FaToggleOff
                                    className="hover:cursor-pointer text-red-600"
                                    size={25}
                                    onClick={() =>
                                      onToggleElmExclude(elm.symbol)
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="flex items-center gap-1">
                                  <p className="text-xs min-w-max">include</p>
                                  <FaToggleOn
                                    className="hover:cursor-pointer text-green-600"
                                    size={25}
                                    onClick={() =>
                                      onToggleElmExclude(elm.symbol)
                                    }
                                  />
                                </div>
                              )}

                              <BsFillTrashFill
                                className="text-red-600 hover:cursor-pointer hover:text-red-800"
                                size={25}
                                onClick={() => onClickElement(symbol)}
                              />
                            </div>
                            <div className="flex justify-center gap-2 px-2">
                              <input
                                className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                type="number"
                                min={0}
                                max={100}
                                step="any"
                                value={pRange.from}
                                onChange={(e) =>
                                  onChangeElmPercentFrom(
                                    symbol,
                                    Number(e.target.value)
                                  )
                                }
                                onFocus={handleFocus}
                                disabled={
                                  selectedElmsToFilter.get(elm.symbol)?.exclude
                                }
                              />
                              to
                              <input
                                className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                type="number"
                                min={0}
                                max={100}
                                step="any"
                                value={pRange.to}
                                onChange={(e) =>
                                  onChangeElmPercentTo(
                                    symbol,
                                    Number(e.target.value)
                                  )
                                }
                                onFocus={handleFocus}
                                disabled={
                                  selectedElmsToFilter.get(elm.symbol)?.exclude
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}

                  {Array.from(selectedOxidesToFilter.entries()).map(
                      ([oxide, pRange], _) => {
                        // const elm = elements.find((elm) => elm.symbol === oxide);
                        // if (!elm) return <></>;

                        return (
                            <div key={oxide} className="flex w-full gap-2">
                              <div
                                  className={
                                      "flex flex-col w-full py-3 px-2 bg-white shadow-md rounded-md" +
                                      " " +
                                      (pRange.to !== undefined
                                          ? pRange.from > pRange.to && pRange.to > 0
                                              ? "border border-red-600"
                                              : ""
                                          : "")
                                  }
                                  onFocus={handleFocus}
                              >
                                <div className="w-full flex px-2 mb-1 gap-2">
                                  <h1 className="w-full">{parse(formatSubscriptWithHTML(oxide))}</h1>

                                  {selectedElmsToFilter.get(oxide)?.exclude ===
                                  true ? (
                                      <div className="flex items-center gap-1">
                                        <p className="text-xs min-w-max">exclude</p>
                                        <FaToggleOff
                                            className="hover:cursor-pointer text-red-600"
                                            size={25}
                                            onClick={() =>
                                                onToggleOxideExclude(oxide)
                                            }
                                        />
                                      </div>
                                  ) : (
                                      <div className="flex items-center gap-1">
                                        <p className="text-xs min-w-max">include</p>
                                        <FaToggleOn
                                            className="hover:cursor-pointer text-green-600"
                                            size={25}
                                            onClick={() =>
                                                onToggleOxideExclude(oxide)
                                            }
                                        />
                                      </div>
                                  )}

                                  <BsFillTrashFill
                                      className="text-red-600 hover:cursor-pointer hover:text-red-800"
                                      size={25}
                                      onClick={() => onClickOxide(oxide)}
                                  />
                                </div>
                                <div className="flex justify-center gap-2 px-2">
                                  <input
                                      className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                      type="number"
                                      min={0}
                                      max={100}
                                      step="any"
                                      value={pRange.from}
                                      onChange={(e) =>
                                          onChangeOxidePercentFrom(
                                              oxide,
                                              Number(e.target.value)
                                          )
                                      }
                                      onFocus={handleFocus}
                                      disabled={
                                        selectedElmsToFilter.get(oxide)?.exclude
                                      }
                                  />
                                  to
                                  <input
                                      className="w-16 border-b-2 text-right outline-none focus:border-neutral-400"
                                      type="number"
                                      min={0}
                                      max={100}
                                      step="any"
                                      value={pRange.to}
                                      onChange={(e) =>
                                          onChangeOxidePercentTo(
                                              oxide,
                                              Number(e.target.value)
                                          )
                                      }
                                      onFocus={handleFocus}
                                      disabled={
                                        selectedElmsToFilter.get(oxide)?.exclude
                                      }
                                  />
                                </div>
                              </div>
                            </div>
                        );
                      }
                  )}
                </div>
              </div>
            </div>

            <div className="flex justify-center items-center gap-2 border-[1px] p-2 w-full rounded-md">
              <button
                  type="button"
                  className={
                    "px-12 py-2 text-white rounded-md shadow-md transition-all bg-orange-500 hover:bg-orange-600"
                  }
                  onClick={() => onClickQuery()}
              >
                Query Database
              </button>
            </div>

            {/* Utility Bar */}
            <div className="px-4 py-2 bg-neutral-100 rounded-lg">
              <div className="w-full flex flex-col lg:flex-row items-center justify-between">
                <div className="flex flex-col lg:flex-row gap-4 w-full min-w-max">
                  <div className="flex min-w-max drop-shadow-md">
                    <div className="h-full px-3 rounded-tl-md rounded-bl-md text-white bg-sky-700">
                      <BsSearch className="h-full" strokeWidth={1} />
                    </div>
                    <input
                      type="text"
                      className="px-4 py-2 rounded-tr-md rounded-br-md font-light min-w-[32rem] focus:outline-none"
                      value={strSearch}
                      onChange={(e) => setStrSearch(e.target.value)}
                      placeholder="Search queried data"
                    />
                  </div>
                </div>
                <div className="flex flex-row-reverse w-full min-w-max items-center gap-4">
                  {parsedData.length > 1 ? (
                    <div className="inline-flex items-center rounded-md border px-4 py-2 text-white text-sm font-light transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent bg-primary text-primary-foreground shadow hover:bg-primary/80 bg-[#03457b]">
                      {"Rows Found: " + totalCount }
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>

            {/* Data Display Table */}
            <div>
              {totalCount ? (
                <DataDisplayTable
                  parsedData={parsedData}
                  strSearch={strSearch}
                  totalCount={totalCount}
                  selectedElmsToFilter={selectedElmsToFilter}
                  selectedOxidesToFilter={selectedOxidesToFilter}
                  propertiesToValueRange={propertiesToValueRange}
                  activeProperties={activeProperties}
                  setIsLoading={setIsLoading}
                  setErrorMsg={setErrorMsg}
                  setData={setData}
                  setParsedData={setParsedData}
                  selectedDb={selectedDb}
                  setSnackbarOpen={setSnackbarOpen}
                />

              ) : (
                <div className="py-8 bg-neutral-100 rounded-lg text-center font-light">
                  {totalCount ? data : "No data"}
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      {/*{hasDataAccess ? <></> : <DataAccessRequestModel/>}*/}
      <SnackbarNotification message={errorMsg} open={snackbarOpen} onClose={handleSnackbarClose} />
    </>
  );
}
