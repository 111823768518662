import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";
import { useNavigate } from "react-router-dom";

import bg from "./bg/srnl_logo.png"

import * as apiUser from "../../api/user";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const boxstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const center = {
  position: "relative",
  top: "50%",
  left: "30%",
};

export default function ForgotPassword() {

  const vertical = "top";
  const horizontal = "right";
  const navigate = useNavigate();

    const [username, setUsername] = useState("");

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const [showErrorMsg, setShowErrorMsg] = useState("Failed! Enter valid email");
    const [showSuccessMsg, setShowSuccessMsg] = useState("Success! Password Reset Link has been sent.");

    const isEmailValid = (email) => {
        // Regular expression to validate email format
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailPattern.test(email);
    };
    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

  const handleSubmit = async (event) => {
    try {
        event.preventDefault();

        if (!isEmailValid(username)) {
            setShowError(true)
            return
        }

        const data = await apiUser.forgotPassword(username)

        if (data.resp.statusCode === 200) {
            setShowSuccess(true)
            setShowSuccessMsg(data.resp.message)
            return
        }

        setShowError(true)
        setShowErrorMsg(data.resp.message)

    } catch (error) {
        //console.log("handleSubmit error", error)

        setShowError(true)
        setShowErrorMsg("Server Error")
        return
    }
  };

    const handleShowErrorClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowError(false);
    };

    const handleShowSuccessClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setShowSuccess(false);
    };

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  return (
    <>
        <Snackbar
            open={showError}
            autoHideDuration={3000}
            onClose={handleShowErrorClose}
            TransitionComponent={TransitionLeft}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert onClose={handleShowErrorClose} severity="error" sx={{ width: "100%" }}>
                {showErrorMsg}
            </Alert>
        </Snackbar>
        <Snackbar
            open={showSuccess}
            autoHideDuration={3000}
            onClose={handleShowSuccessClose}
            TransitionComponent={TransitionLeft}
            anchorOrigin={{ vertical, horizontal }}
        >
            <Alert onClose={handleShowSuccessClose} severity="success" sx={{ width: "100%" }}>
                {showSuccessMsg}
            </Alert>
        </Snackbar>
      <div
        style={{
          //backgroundImage: `url(${bgimg})`,
          backgroundSize: "cover",
          height: "100vh",
          color: "#f5f5f5",
        }}
      >
        <Box sx={boxstyle}>
          <Grid container>
            {/*<Grid item xs={12} sm={12} lg={6}>*/}
            {/*  <Box*/}
            {/*    style={{*/}
            {/*      backgroundImage: `url(${bg})`,*/}
            {/*      backgroundSize: "cover",*/}
            {/*      marginTop: "40px",*/}
            {/*      marginLeft: "15px",*/}
            {/*      marginRight: "15px",*/}
            {/*      height: "63vh",*/}
            {/*      color: "#f5f5f5",*/}
            {/*    }}*/}
            {/*  ></Box>*/}
            {/*</Grid>*/}
            <Grid >
              <Box
                style={{
                  backgroundSize: "cover",
                  height: "70vh",
                  minHeight: "500px",
                  backgroundColor: "#03457b",
                }}
              >
                <ThemeProvider theme={darkTheme}>
                  <Container>
                    <Box height={35} />
                    <Box sx={center}>
                      <Avatar
                        sx={{ ml: "85px", mb: "4px", bgcolor: "#ffffff" }}
                      >
                        <LockOutlinedIcon />
                      </Avatar>
                      <Typography component="h1" variant="h4">
                          Reset Password
                      </Typography>
                    </Box>
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleSubmit}
                      sx={{ mt: 2 }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <TextField
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            value={username}
                            onChange={handleUsernameChange}
                          />
                        </Grid>

                        <Grid item xs={12} sx={{ ml: "5em", mr: "5em" }}>
                          <Button
                            type="submit"
                            variant="contained"
                            fullWidth="true"
                            size="large"
                            sx={{
                              mt: "15px",
                              mr: "20px",
                              borderRadius: 28,
                              color: "#ffffff",
                              minWidth: "170px",
                              backgroundColor: "#FF9A01",
                            }}
                          >
                              Send Reset Link
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ ml: "3em", mr: "3em" }}>
                          <Stack direction="row" spacing={2}>
                            <Typography
                              variant="body1"
                              component="span"
                              style={{ marginTop: "10px" }}
                            >
                              Already have an Account?{" "}
                              <span
                                style={{ color: "#beb4fb", cursor: "pointer" }}
                                onClick={() => {
                                    navigate("/login");
                                  }}
                              >
                                Sign In
                              </span>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Container>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
