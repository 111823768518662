import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
interface SnackbarNotificationProps {
    message: string;
    open: boolean;
    onClose: () => void;
}

const SnackbarNotification: React.FC<SnackbarNotificationProps> = ({ message , open, onClose}) => {

    return (
        <Snackbar
            open={open}
            onClose={onClose}
            message={message}
            autoHideDuration={2000}
        />
    );
};

export default SnackbarNotification;
