import { useEffect, useState } from "react";

import Sidebar from "./AdminSidebar";
import UpdateDatabase from "./UpdateDatabase";
import ManageAccounts from "./ManageAccounts";
import AccessControlMain from "./AccessControl/AccessControlMain";

const Admin = () => {
  const [pageID, setPageID] = useState<number>(1);

  useEffect(() => {
    setPageID(1);
  }, []);

  const getComponent = (pageID: number): JSX.Element => {
    switch (pageID) {
      case 1:
        return <UpdateDatabase />;
      case 2:
        return <ManageAccounts />
      case 3:
        return <AccessControlMain />
      default:
        return (
          <div className="flex w-full h-full items-center justify-center bg-neutral-100">
            Under Construction
          </div>
        );
    }
  };

  return (
    <div className="w-full h-full flex">
      <nav className="sticky w-1/6 min-w-fit">
        <Sidebar setPageID={setPageID} />
      </nav>
      <div className="p-4 w-5/6 h-screen overflow-x-hidden overflow-y-auto bg-neutral-100">
        {getComponent(pageID)}
      </div>
    </div>
  );
};

export default Admin;
