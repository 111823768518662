import axios from "axios";
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.defaults.withCredentials = true;

// Function to store the new access token in local storage
const updateAccessToken = (newAccessToken: string) => {
  localStorage.setItem('access_token', newAccessToken);
};

//Axios request interceptor to add the access token to every request
instance.interceptors.request.use((req: any) => {

  const accessToken = localStorage.getItem('access_token') || "";

  if (accessToken) {
      req.headers.Authorization = `Bearer ${accessToken}`;
  }

  return req;
});


// Axios response interceptor to handle 401 Unauthorized and refresh the token
instance.interceptors.response.use(
    (response) => {
        return response
    }, async (error) => {

      const originalRequest = error.config;

      // If the request returns 401 Unauthorized
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {

          // Update the access token in local storage
            if (error.response.data.exists) {
                updateAccessToken(error.response.data.access_token);
            }

          // Retry the original request with the new access token
            originalRequest.headers.Authorization = `Bearer ${error.response.data.access_token}`;

            return await axios({
                ...originalRequest,
                headers: originalRequest.headers.toJSON()
            });

            //return axios(originalRequest);
        } catch (refreshError) {
          // Handle refresh token error (e.g., refresh token expired)
          //handleServerError()
            //console.log("error: ", refreshError)
            handleServerError()
        }
      }

      return Promise.reject(error);
    }
);

// Function to redirect to login page
// Function to handle server error or network issues
function handleServerError() {
    localStorage.clear();
    if (window.location.pathname !== '/login') {
        window.location.pathname = '/login';
    }
}


export default instance;
