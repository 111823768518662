import React from 'react';
import {FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, OutlinedInput} from '@mui/material';

// Define a type for the options prop
interface Option {
    label: string;
    value: string | number;
}

// Define a type for the component props
interface MenuSelectProps {
    label: string;
    options: Option[];
    value: string | number;
    onChange: (event: SelectChangeEvent<string | number>) => void; // Updated type for event parameter
}

const MenuSelect: React.FC<MenuSelectProps> = ({ label, options, value, onChange }) => {
    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={onChange}
                label={label} // Ensure the label is applied correctly for the outlined variant
                input={<OutlinedInput label={label} />} // Use OutlinedInput for a consistent outlined style
            >
                <MenuItem disabled value="">
                    <em>Choose a database</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default MenuSelect;
