import * as React from 'react';

interface BasicTextFieldProps {
    placeholder?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function BasicTextField({placeholder, value, onChange}: BasicTextFieldProps = {placeholder: "Enter text here", value: "", onChange: () => {  }}) {
    return (
    <input
        type="text"
        className={
            "w-full px-2 py-2 bg-white border rounded-md text-sm"
        }
        value={value}
        onChange={onChange}
        placeholder={placeholder}
    />

    );
}
