import { Outlet } from "react-router-dom";
import NavBar from "./Navbar";

export default function Layout() {
  return (
    <div className="">
      <NavBar />
      <main className="min-h-screen">
        <Outlet />
      </main>
    {/*<footer className="py-4 bg-neutral-100 border border-t-1 border-neutral-300 text-center font-light text-sm">*/}
    {/*    Copyright All Rights Reserved 2023 by Savannah River National Laboratory*/}
    {/*</footer>*/}
        <footer className="mt-40 py-4 bg-neutral-100 border border-t-1 text-center font-light text-sm">
            Copyright All Rights Reserved 2024 by Savannah River National Laboratory
        </footer>
    </div>
  );
}
