import * as React from 'react';
import StickyHeadTable from './ClientTable';
import EditDialog from './EditDialog';
import { DatabaseOptions, AllRoles, SecondaryRoles } from '../../Constants/base';
import { Column, Data } from './types';

const columns: readonly Column<Data>[] = [
    { id: 'database', label: 'Database', minWidth: 170 },
    { id: 'primaryRole', label: 'Primary Access', minWidth: 100 },
    { id: 'secondaryRoles', label: 'Secondary Access', minWidth: 170 },
];

const initialRows: Data[] = DatabaseOptions.map(db => ({
    database: db.label,
    primaryRole: AllRoles[0].label,
    secondaryRoles: [],
}));

function AccessControlMain() {
    const [rows, setRows] = React.useState<Data[]>(initialRows);
    const [editingRow, setEditingRow] = React.useState<Data | null>(null);

    const handleEdit = (row: Data) => {
        setEditingRow(row);
    };

    const handleSave = (updatedRow: Data) => {
        setRows((prevRows) =>
            prevRows.map((row) => (row.database === updatedRow.database ? updatedRow : row))
        );
    };

    const handleClose = () => {
        setEditingRow(null);
    };

    return (
        <div>
            <StickyHeadTable columns={columns} rows={rows} onEdit={handleEdit} />
            {editingRow && (
                <EditDialog
                    open={Boolean(editingRow)}
                    onClose={handleClose}
                    data={editingRow}
                    onSave={handleSave}
                />
            )}
        </div>
    );
}

export default AccessControlMain;
