import * as React from "react";
import BasicTextField from "./BasicTextField";
import ContainedButtons from "./ContainedButtons";
import DenseTable from "./DenseTable";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarNotification from "./SnackbarNotification";
import { Close } from "@mui/icons-material";
import CustomDenseTable from "./CustomDenseTable";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import {notifyPopulateSuccess} from "../../utils";
import {useEffect} from "react";

interface Composition {
    composition: string;
    from: string;
    to: string;
}

interface PredictedData {
    [key: string]: {
        [key: string]: number;
    };
}

const isEmpty = (obj: Object) => {
    return !obj || Object.keys(obj).length === 0;
};

export default function GlassNetPredict() {
    const [predictedData, setPredictedData] = React.useState<any>({});
    const [composition, setComposition] = React.useState<string>("");
    const [from, setFrom] = React.useState<string>("");
    const [to, setTo] = React.useState<string>("");
    const [compositions, setCompositions] = React.useState<Composition[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string>("");
    const [snackbarOpen, setSnackbarOpen] = React.useState<boolean>(false);

    const onChangeComposition = (e: React.ChangeEvent<HTMLInputElement>) => setComposition(e.target.value);
    const onChangeFrom = (e: React.ChangeEvent<HTMLInputElement>) => setFrom(e.target.value);
    const onChangeTo = (e: React.ChangeEvent<HTMLInputElement>) => setTo(e.target.value);

    useEffect(() => {
        if (message) {
            notifyPopulateSuccess(message)
        }
    }, [
        message,
    ]);

    useEffect(() => {
        if (errorMessage) {
            notifyPopulateSuccess(errorMessage)
        }
    }, [errorMessage]);

    const validateInputs = () => {
        const fromInt = parseInt(from);
        const toInt = parseInt(to);

        if (isNaN(fromInt) || isNaN(toInt)) {
            setMessage("Both 'from' and 'to' must be integers.");
            setSnackbarOpen(true);
            return false;
        }

        if (fromInt >= toInt) {
            setMessage("'From' must be less than 'To'.");
            setSnackbarOpen(true);
            return false;
        }

        return true;
    };

    const onSubmit = async () => {
        try {
            setLoading(true);
            setPredictedData({});

            const formattedCompositions = compositions.reduce<{ composition: { [key: string]: [number, number] } }>((acc, comp) => {
                acc.composition[comp.composition] = [parseInt(comp.from), parseInt(comp.to)];
                return acc;
            }, { composition: {} });

            const response = await fetch(`${process.env.REACT_APP_GLASSNET_MODEL_MULTI}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify([formattedCompositions])
            }).then(response => response.json());

            const transformedData = transformDataForTable(response.data);

            setPredictedData(transformedData);

            if (isEmpty(response.data)) {
                setMessage("No data found for the given composition.");
                setSnackbarOpen(true);
            }

            //console.log("data: ", response.data)

            setLoading(false);
        } catch (error) {
            //console.error(error);
            setLoading(false);
            setErrorMessage("Request couldn't be processed.");
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const onAdd = () => {
        if (composition && from && to && validateInputs()) {
            setCompositions([...compositions, { composition, from, to }]);
            setComposition("");
            setFrom("");
            setTo("");
        }
    };

    const onRemove = (index: number) => {
        setCompositions(compositions.filter((_, i) => i !== index));
    };

    const transformDataForTable = (data: any[]): any[] => {
        const rows: any[] = [];

        data.forEach((item) => {
            const row: { composition: string; [key: string]: any } = { composition: JSON.stringify(item.composition) };
            Object.keys(item.predictions).forEach((key) => {
                row[key] = item.predictions[key][0];
            });
            rows.push(row);
        });

        return rows;
    };

    const downloadCSV = () => {
        try {
            const csv = Papa.unparse(predictedData);
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            saveAs(blob, "predictedData.csv");
        } catch (err) {
            //console.error("Error converting to CSV: ", err);
            setErrorMessage("Error converting to CSV.");
        }
    };

    return (
        <div className="flex flex-col gap-2 w-full ">
            <div className="text-sm mt-2 mb-2">
                <p>
                    GlassNet is a powerful tool for predicting glass properties based on compositional ranges. To learn more about the model and its applications, visit the{" "}
                    <a href="https://glasspy.readthedocs.io/en/latest/intro/notebooks/glasspy_predict.html" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">
                        GlassNet documentation
                    </a>.
                </p>
            </div>
            <div className="flex gap-2 items-center w-full mb-5">
                <div className="flex gap-2 items-center border-2 rounded-md p-2 lg:min-w-96 h-14">
                    <BasicTextField
                        placeholder="component"
                        value={composition}
                        onChange={onChangeComposition}
                    />
                    <BasicTextField
                        placeholder="from"
                        value={from}
                        onChange={onChangeFrom}
                    />
                    <BasicTextField
                        placeholder="to"
                        value={to}
                        onChange={onChangeTo}
                    />
                    <ContainedButtons action="add" onSubmit={onAdd} />
                </div>
                <div className="flex gap-2">
                    {compositions.map((comp, index) => (
                        <div key={index} className="flex items-center justify-between gap-2 border-2 rounded-md p-2 bg-neutral-100">
                            <span className="text-sm font-bold">{comp.composition + ": "}</span>
                            <span className="text-sm font-light">{comp.from}</span>
                            <span className="text-sm font-light">to</span>
                            <span className="text-sm font-light">{comp.to}</span>
                            <Close className="cursor-pointer" onClick={() => onRemove(index)} />
                        </div>
                    ))}
                </div>
                <div className="flex justify-end gap-2 w-full ">
                    {!loading && <ContainedButtons action="predict" onSubmit={onSubmit} disabled={compositions.length === 0} />}
                    {loading && <CircularProgress />}
                </div>
            </div>
            <span className="text-xs font-light mt-2 mb-2">*Note: It generates maximum of 100 composition using even spacing based on the composition ranges you provide, we create multiple possible combinations of values for each component. This allows us to predict and analyze various scenarios within the specified ranges.</span>
            {!isEmpty(predictedData) && (
                <>
                    <CustomDenseTable data={predictedData} />
                    <div className="flex justify-center mt-4">
                        <ContainedButtons action="Download CSV" onSubmit={downloadCSV} />
                    </div>
                </>
            )}
            {/*<SnackbarNotification message={message} open={snackbarOpen} onClose={handleSnackbarClose} />*/}
        </div>
    );
}
