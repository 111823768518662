import React from 'react';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import FilterListIcon from '@mui/icons-material/FilterList'; // Importing the filter list icon
import { ButtonProps } from '@mui/material/Button';

interface FilterToggleProps {
    showFilters: boolean;
    setShowFilters: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
}

export const FilterToggle: React.FC<FilterToggleProps> = ({ showFilters, setShowFilters, children }) => {
    const toggleFilterOptions = () => {
        setShowFilters(!showFilters);
    };

    // Define the button styles and properties
    const buttonStyles: Partial<ButtonProps> = {
        variant: 'contained',
        size: 'small',  // Set the button size to 'small'
        color:  'primary',
        sx: {
            marginBottom: 1, // Adjust the margin bottom here
            // Additional styles can be added here if needed
        },
        startIcon: <FilterListIcon /> // Adding the icon to the button
    };

    return (
        <div>
            <Button
                {...buttonStyles}
                onClick={toggleFilterOptions}
            >
                {showFilters ? 'Hide Filter' : 'Show Filter'}
            </Button>
            <Collapse in={showFilters}>
                {children}
            </Collapse>
        </div>
    );
};
