import http from "./_http";
import { resolve, Resolved } from "./_resolve";

const baseUrlPath = "/api/admin/";

interface RequestBodyUploadDatabase {
  collectionName: string;
  data: Array<Array<string>>;
  compColIdxFrom: number;
  compColIdxTo: number;
  propColIdxFrom: number;
  propColIdxTo: number;
}

export async function populate(
  databaseName: string,
  data: Array<Array<string>>,
  compColIdxFrom: number,
  compColIdxTo: number,
  propColIdxFrom: number,
  propColIdxTo: number
): Promise<Resolved> {
  const body: RequestBodyUploadDatabase = {
    collectionName: databaseName,
    data: data,
    compColIdxFrom: compColIdxFrom,
    compColIdxTo: compColIdxTo,
    propColIdxFrom: propColIdxFrom,
    propColIdxTo: propColIdxTo,
  };

  return await resolve(http.post(baseUrlPath + "database", body));
}


export async function DeleteCollection(
    databaseName: string,
): Promise<Resolved> {

  return await resolve(http.delete(baseUrlPath + "database/delete"+`?databaseName=${databaseName}`));
}