export const DatabaseOptions = [
    { label: 'Database v1', value: 1 },
    { label: 'Database v2', value: 2 },
    { label: 'beta_test', value: 3 },
];

export const AllRoles = [
    { label: 'admin', value: 1 },
    { label: 'user', value: 2 },
    { label: 'tester', value: 3 },
]

export const SecondaryRoles = [
    { label: 'user', value: 2 },
    { label: 'tester', value: 3 },
]