import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import ColumnGroupingTable from "./ColumnGroupingTable";
import GlassNetPredict from "./GlassNetPredict";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', marginTop: '1rem', padding: '1rem', border: 2, borderColor: 'divider', borderRadius: '8px' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {/*<Tab label="Sci Glass Data" {...a11yProps(0)} />*/}
                    <Tab label="GlassNet" {...a11yProps(0)} />
                </Tabs>
            </Box>
            {/*<CustomTabPanel value={value} index={0}>*/}
            {/*   <ColumnGroupingTable />*/}
            {/*</CustomTabPanel>*/}
            <CustomTabPanel value={value} index={0}>
                <GlassNetPredict />
            </CustomTabPanel>
        </Box>
    );
}
