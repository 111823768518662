import { ElementFilter, NumberRange } from "../components/Database/Database";
import http from "./_http";
import { resolve, Resolved } from "./_resolve";

const baseUrlPath = "/api/database/";

export async function getSupportedElements(dbVersion: number): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "elements"+`?dbVersion=${dbVersion}`));
}

export async function getSupportedProperties(DbVersion: number): Promise<Resolved> {
  return await resolve(http.get(baseUrlPath + "properties"+`?DbVersion=${DbVersion}`));
}

export async function query(
  elmsToFilter: Map<string, ElementFilter>,
  propsToPercentRange: Map<string, NumberRange>,
  pageNumber: number,
  pageSize: number,
  selectedDb: number,
): Promise<Resolved> {
  const body: {
    compositionFilters: Array<{
      symbol: string;
      from: number;
      to?: number;
      exclude: boolean;
    }>;
    propertyFilters: Array<{ property: string; from: number; to?: number }>;
    pageSize: number;
    pageNumber: number;
    DBVersion: number;
  } = { compositionFilters: [], propertyFilters: [], pageSize: pageSize, pageNumber: pageNumber, DBVersion:1 };

  for (const [symbol, filter] of elmsToFilter) {
    body.compositionFilters.push({
      symbol: symbol,
      from: filter.from,
      to: filter.to,
      exclude: filter.exclude === true,
    });
  }

  for (const [label, pRange] of propsToPercentRange) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  body.DBVersion = selectedDb;

  //console.log("body: ", body, baseUrlPath)
  return await resolve(http.post(baseUrlPath + "query", body));
}

export async function queryCount(
    elmsToFilter: Map<string, ElementFilter>,
    propsToPercentRange: Map<string, NumberRange>,
    selectedDb: number,
): Promise<Resolved> {

  const body: {
    compositionFilters: Array<{
      symbol: string;
      from: number;
      to?: number;
      exclude: boolean;
    }>;
    propertyFilters: Array<{ property: string; from: number; to?: number }>;
    DBVersion: number;
  } = { compositionFilters: [], propertyFilters: [], DBVersion: 1 };

  for (const [symbol, filter] of elmsToFilter) {
    body.compositionFilters.push({
      symbol: symbol,
      from: filter.from,
      to: filter.to,
      exclude: filter.exclude === true,
    });
  }

  for (const [label, pRange] of propsToPercentRange) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  body.DBVersion = selectedDb;

  return await resolve(http.post(baseUrlPath + "count", body));
}

export async function queryWithParameter(
    elmsToFilter: Map<string, ElementFilter>,
    propsToPercentRange: Map<string, NumberRange>,
    pageNumber: number,
    pageSize: number,
    selectedDb: number,
    properties: string[],
): Promise<Resolved> {
  const body: {
    compositionFilters: Array<{
      symbol: string;
      from: number;
      to?: number;
      exclude: boolean;
    }>;
    propertyFilters: Array<{ property: string; from: number; to?: number }>;
    pageSize: number;
    pageNumber: number;
    DBVersion: number;
    properties: string[];
  } = { compositionFilters: [], propertyFilters: [], pageSize: pageSize, pageNumber: pageNumber, DBVersion:1, properties: properties };

  for (const [symbol, filter] of elmsToFilter) {
    body.compositionFilters.push({
      symbol: symbol,
      from: filter.from,
      to: filter.to,
      exclude: filter.exclude === true,
    });
  }

  for (const [label, pRange] of propsToPercentRange) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  body.DBVersion = selectedDb;

  //console.log("body: ", body, baseUrlPath)
  return await resolve(http.post(baseUrlPath + "query/filter", body));
}

export async function FetchAxis(
    elmsToFilter: Map<string, ElementFilter>,
    propsToPercentRange: Map<string, NumberRange>,
    pageNumber: number,
    pageSize: number,
    selectedDb: number,
    properties: string[],
): Promise<Resolved> {
  const body: {
    compositionFilters: Array<{
      symbol: string;
      from: number;
      to?: number;
      exclude: boolean;
    }>;
    propertyFilters: Array<{ property: string; from: number; to?: number }>;
    pageSize: number;
    pageNumber: number;
    DBVersion: number;
    properties: string[];
  } = { compositionFilters: [], propertyFilters: [], pageSize: pageSize, pageNumber: pageNumber, DBVersion:1, properties: properties };

  for (const [symbol, filter] of elmsToFilter) {
    body.compositionFilters.push({
      symbol: symbol,
      from: filter.from,
      to: filter.to,
      exclude: filter.exclude === true,
    });
  }

  for (const [label, pRange] of propsToPercentRange) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  body.DBVersion = selectedDb;

  //console.log("body: ", body, baseUrlPath)
  return await resolve(http.post(baseUrlPath + "fetch/axis", body));
}

export async function awsDownload(
    elmsToFilter: Map<string, ElementFilter>,
    propsToPercentRange: Map<string, NumberRange>,
    pageNumber: number,
    pageSize: number,
    selectedDb: number,
    selectedDbLabel: string | number,
): Promise<Resolved> {
  const body: {
    compositionFilters: Array<{
      symbol: string;
      from: number;
      to?: number;
      exclude: boolean;
    }>;
    propertyFilters: Array<{ property: string; from: number; to?: number }>;
    pageSize: number;
    pageNumber: number;
    DBVersion: number;
  } = { compositionFilters: [], propertyFilters: [], pageSize: pageSize, pageNumber: pageNumber, DBVersion:0 };

  for (const [symbol, filter] of elmsToFilter) {
    body.compositionFilters.push({
      symbol: symbol,
      from: filter.from,
      to: filter.to,
      exclude: filter.exclude === true,
    });
  }

  for (const [label, pRange] of propsToPercentRange) {
    body.propertyFilters.push({
      property: label,
      from: pRange.from,
      to: pRange.to,
    });
  }

  body.DBVersion = selectedDb;

  //console.log("body: ", body, baseUrlPath)
  return await resolve(http.post(baseUrlPath + `aws?filename=${selectedDbLabel}`, body));
}